<template>
  <div class="banner">
    <div class="title">
      <img width="320" height="56" alt="logo" src="@/assets/logo_h.png" />
    </div>
    <div class="sub-title">数字工程 全域赋能</div>
    <a-carousel
      class="carousel"
      show-arrow="never"
      indicator-class="indicator"
      :auto-play="{ interval: 6000 }"
    >
      <a-carousel-item class="banner1" @click="handleClick"></a-carousel-item>
      <a-carousel-item class="banner2"></a-carousel-item>
      <a-carousel-item class="banner3"></a-carousel-item>
    </a-carousel>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
const router = useRouter();
const handleClick = () => {
  router.push("/brand-display");
};
</script>

<style lang="scss" scoped>
.banner {
  position: relative;
  .title {
    position: absolute;
    top: 280px;
    left: 360px;
    z-index: 1000;
    width: 164px;
    height: 100px;
    font-size: 72px;
    font-weight: bold;
    color: #fff;
    line-height: 100px;
    letter-spacing: 10px;
  }
  .sub-title {
    position: absolute;
    top: 399px;
    left: 360px;
    z-index: 1000;
    width: 520px;
    height: 81px;
    font-size: 58px;
    font-weight: 500;
    color: #fff;
    line-height: 78px;
    letter-spacing: 4px;
  }
}
.carousel {
  width: 100%;
  min-width: 1200px;
  height: 840px;
  .banner1 {
    background: url("@/assets/home/banner1.png");
    background-size: cover;
    background-position: center;
  }
  .banner2 {
    background: url("@/assets/home/banner2.png");
    background-size: cover;
    background-position: center;
  }
  .banner3 {
    background: url("@/assets/home/banner3.png");
    background-size: cover;
    background-position: center;
  }
}
:deep(.indicator) {
  bottom: 72px;
  .arco-carousel-indicator-item {
    width: 40px;
    height: 3px;
    border-radius: 0;
  }
  .arco-carousel-indicator-item:hover,
  .arco-carousel-indicator-item-active {
    background: linear-gradient(137deg, #00ffa0 0%, #00d2ff 25%, #0064ff 100%);
  }
}
</style>
