/*
 * @Author: zhong_m
 * @Date: 2022-01-14 14:28:24
 * @LastEditTime: 2022-04-25 18:19:52
 * @LastEditors: chen_yt
 * @Description: 可选值的枚举
 * @FilePath: \central-system\src\config\enums.js
 */

// 验证方式枚举

const CAPTCHA_TYPE = {
  CLICK_WORD: "clickWord",
  BLOCK_PUZZLE: "blockPuzzle",
};
// socket连接方式
const SOCKET_TYPE = {
  SMQTT: "smqtt",
  STOMP: "stomp",
};
// 凤翎支持的授权方式
const GRANT_TYPE = {
  PASSWORD: "password",
  AUTHORIZATION_CODE: "authorization_code",
  SSO: "sso",
  SMS_CODE: "sms_code",
  SMS_CAPTCHA: "sms_captcha",
  IMG_CAPTCHA: "img_captcha",
  REFRESH_TOKEN: "refresh_token",
  HDEC_SSO_PASSWORD: "hdec_sso_password",
};

module.exports = { CAPTCHA_TYPE, SOCKET_TYPE, GRANT_TYPE };
