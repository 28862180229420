/*
 * @Author: wei_jt@ecidi.com
 * @Date: 2019-11-07 09:36:15
 * @LastEditors: wei_jt@ecidi.com
 * @LastEditTime: 2022-01-28 17:28:07
 * @Description: 应用相关接口
 */
import request from "@/utils/request";
import { resetMessage } from "@/utils/message";
import Qs from "qs";

/**
 * @description: 获取token
 * @param {*}
 * @return {*}
 */
export function getToken(data) {
  return request({
    url: "/sys-auth/oauth/token",
    method: "post",
    data: {
      scope: "all",
      ...data,
    },
    transformRequest: [
      function (data) {
        data = Qs.stringify(data); //序列化参数
        return data;
      },
    ],
  }).catch((e) => {
    resetMessage.warning(e.response.data);
  });
}
/**
 * @description: 登出
 * @param {*}
 * @return {*}
 */
/** */
export function loginOut() {
  return request({
    url: "/sys-auth/oauth/exit",
    method: "delete",
  });
}

/**
 * @description: 获取语言资源
 * @param {*}
 * @return {*}
 */
//
export function getLang(langCode) {
  return request({
    url: "/sys-system/lang/detail/name",
    method: "GET",
    params: {
      langCode,
    },
  });
}

/**
 * @description: 获取应用配置
 * @param {*}
 * @return {*}
 */
export function getClientConfig() {
  return request({
    url: "/sys-system/clientInfo",
    method: "GET",
  });
}
/**
 * 设置应用配置
 * @param {*} data
 * @returns
 */
export function setClientConfig(data) {
  return request({
    url: "/sys-system/client",
    method: "put",
    data: data,
  });
}
/**
 * @description: 第三方应用登录
 * @param {*} token
 * @return {*}
 */
export function getSocialInfoByToken(token) {
  return request({
    method: "get",
    url: "/sys-auth/oauth/user_info",
    headers: {
      Authorization: "bearer " + token,
    },
  });
}
/**
 * @description: 绑定第三方应用
 * @param {*} data
 * @return {*}
 */
export function socialBind(data) {
  return request({
    method: "POST",
    url: "/sys-user/oauth/user/bind",

    data: {
      access_token: data.access_token,
      grant_type: data.grant_type,
      password: data.password,
      scope: data.scope ? data.scope : "all",
      username: data.username,
    },
    ctM: true,
  });
}
/**
 * @description: 获取服务器时间戳
 * @param {*}
 * @return {*}
 */
export function getTs() {
  return request({
    method: "GET",
    url: "/sys-gateway/sign/ts",
    timeout: 1000,
  });
}
export function getClientCatch(data) {
  return request({
    url: "/sys-system/clients/cache/sync",
    params: data,
    method: "get",
  });
}

export function getUserCache(data) {
  return request({
    url: "/sys-user/users/cache/sync",
    params: data,
    method: "get",
  });
}
export function getAuthCache(data) {
  return request({
    url: "/sys-user/user/auth/cache/sync",
    params: data,
    method: "get",
  });
}
//埋点
export function addMonitor(data) {
  return request({
    url: "/sys-monitor/analysis",
    method: "post",
    data: data,
  });
}

/** 获取按钮列表 */
export function getButtons(params) {
  return request({
    url: "/sys-system/buttons",
    method: "get",
    params: params,
  });
}
