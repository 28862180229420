/*
 * @Author: wei_jt@ecidi.com
 * @Date: 2019-11-01 11:53:07
 * @LastEditors: wei_jt@ecidi.com
 * @LastEditTime: 2022-02-18 14:41:10
 * @Description: 封装axios
 */
import axios from "axios";
import { resetMessage } from "../message";
import store from "@/store";
import { REMOVE_PERMISSION_QUEUE } from "@/store/Mutation/mutationTypes.js";
import { PERMISSION_QUEUE, BUTTONS } from "@/store/State/stateTypes.js";
import * as types from "@/store/Getter/getterTypes.js";
import { getUTCStr, matchTimeToUTC, matchUTCToTime } from "../timezone";
import { getSign } from "@/utils/request/sign";
import { requestEncrypt, responseEncrypt } from "@/config";
import API from "@/config/api";
import { encrypt, decrypt } from "./encrypt";
import { verifyRequest, verifyResponse, verifyUrl } from "./verify";
import storage from "@/utils/storage";
import { removeToken, getToken } from "@/utils/auth";
import { SM4Encrypt } from "@/utils/encryption/sm4";

// create an axios instance
const request = axios.create({
  baseURL: API.VUE_APP_BASE_API, // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests
  // transformRequest: [function (data) {
  //   data = Qs.stringify(data) //序列化参数
  //   return data
  // }],
  timeout: 20000, // request timeout,
});

// request interceptor
request.interceptors.request.use(
  (config) => {
    // 通过请求头传递时区
    const FawkesBiz = {};
    if (store.getters[types.TIMEZONE]) {
      let UTC = getUTCStr(store.getters[types.TIMEZONE]);
      FawkesBiz.timeZone = UTC;
      FawkesBiz.timeFormat = "yyyy-MM-dd HH:mm:ss";
      // 将数据中HH:mm这类时区转为UTC存储
      if (UTC !== "UTC+08:00") {
        if (config.data && !(config.data instanceof FormData)) {
          config.data = JSON.parse(matchTimeToUTC(JSON.stringify(config.data)));
        }
        if (config.params) {
          config.params = JSON.parse(
            matchTimeToUTC(JSON.stringify(config.params))
          );
        }
      }
    }
    //子应用适配
    let currentStore = store;
    //请求头定位权限
    if (currentStore) {
      const currentButton = currentStore.state[BUTTONS].find((button) => {
        return (
          button.url == config.url &&
          currentStore.state[PERMISSION_QUEUE].has(button.code)
        );
      });

      if (currentButton) {
        const permission = currentStore.getters[types.PERMISSIONS].find(
          (permission) => {
            return permission.code == currentButton.code;
          }
        );

        if (!permission) {
          const message = "无接口访问权限，请联系管理员";
          resetMessage.error(message);
          return Promise.reject(new Error(message));
        }

        currentStore.commit(REMOVE_PERMISSION_QUEUE, permission.code);
        FawkesBiz.buttonId = permission.id;
      }
    }
    config.headers["Fawkes-Biz"] = SM4Encrypt(JSON.stringify(FawkesBiz));

    verifyUrl(config, "sign")
      ? (config.params = getSign(config.params))
      : (config.headers["Fawkes-Auth"] = getToken());

    !!requestEncrypt && verifyRequest(config) && (config = encrypt(config));

    return config;
  },
  (error) => {
    // do something with request error
    return Promise.reject(error);
  }
);

// response interceptor
request.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    !!responseEncrypt &&
      verifyResponse(response) &&
      (response = decrypt(response));

    if (response.config.responseType == "blob") {
      return response.data;
    }

    let res = response.data;

    if (verifyUrl(response.config, "cMsg")) {
      return res;
    }

    if (8000000 !== res.code) {
      res.message && resetMessage.warning(res.message);
      return Promise.reject(response);
    }

    // 将数据中HH:mm这类UTC-8时区转为当前时区
    // if (res.data && getUTCStr(store.getters[types.TIMEZONE]) !== "UTC+08:00") {
    //   res.data = JSON.parse(matchUTCToTime(JSON.stringify(res.data)));
    // }

    // if the custom code is not 20000, it is judged as an error.
    return res;
  },

  (error) => {
    let message = "";
    if (error.response) {
      switch (error.response.status) {
        case 400:
          message = "错误请求";
          break;
        case 401:
          storage.remove("access_token");
          removeToken();
          switch (error.response.data.code) {
            case -8000160:
              {
                // confirmHandler(
                //   "无证书提示",
                //   error.response.data.message,
                //   (action) => {
                //     router.push({ path: "/activate" });
                //   }
                // );
              }
              break;
            default: {
              message = error.response.data.message;
              storage.set("redirect", 1);
              // router.push({ path: "/login" });
            }
          }
          break;
        case 403:
          message = "拒绝访问";
          break;
        case 404:
          message = "请求错误,未找到该资源";
          break;
        case 405:
          message = "请求方法未允许";
          break;
        case 408:
          message = "请求超时";
          break;
        case 500:
          message = "服务器端出错";
          break;
        case 501:
          message = "网络未实现";
          break;
        case 502:
          message = "网络错误";
          break;
        case 503:
          message = "服务不可用";
          break;
        case 504:
          message = "网络超时";
          break;
        case 505:
          message = "http版本不支持该请求";
          break;
        default:
          message = `连接错误${error.response.status}`;
      }
      if (error.response.config && verifyUrl(error.response.config, "cMsg")) {
        message = null;
      }
    }
    message && resetMessage.error(message);
    return Promise.reject(error);
  }
);

export default request;
