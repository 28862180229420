<template>
  <div class="joint-ventures">
    <div class="eg-name">JOINT VENTURES</div>
    <div class="name">合资公司</div>
    <router-link to="/ecological-cooperation/joint-ventures" class="company">
      <div class="detail">
        <div class="border"></div>
        <div class="title">
          <div class="sub">合资公司/Joint ventures</div>
          <div class="main">东慧（浙江）科技有限公司</div>
          <div class="c">
            共建、共创、共赢 |
            华东院与Bentley软件举行合资公司成立签约仪式华东院与Bentley软件举行合资公司成立签约仪式！
          </div>
        </div>
        <div class="arrow-group">
          <div class="arrow small">
            <div class="horizontal"></div>
            <div class="right"></div>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.joint-ventures {
  position: relative;
  z-index: 1000;
  width: 100%;
  height: 380px;
  padding-top: 120px;
  background: url("@/assets/home/joint_ventures.png");
  .eg-name {
    position: absolute;
    top: 80px;
    left: 50%;
    width: 550px;
    margin-left: -275px;
    font-size: 48px;
    font-weight: bold;
    letter-spacing: 1px;
    background: linear-gradient(
      180deg,
      #e9edf7 0%,
      rgba(241, 245, 252, 0.68) 100%
    );
    background-clip: text;
    color: transparent;
    text-align: center;
  }
  .name {
    position: relative;
    width: 160px;
    margin: 0 auto 40px;
    font-size: 36px;
    font-weight: 600;
    letter-spacing: 2px;
    text-align: center;
  }
}
.company {
  display: block;
  position: relative;
  width: 1200px;
  height: 500px;
  margin: 0 auto;
  background: url("@/assets/home/company.png");
  background-size: 100% 100%;
  background-position: center;
  transition: background-size 0.5s;
  &:hover {
    background-size: 110% 110%;
    .arrow {
      margin-right: 38px;
      &.small {
        .horizontal {
          width: 50px;
        }
      }
      .horizontal {
        width: 70px;
      }
    }
  }
  .detail {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 96px;
    padding: 20px 0 20px;
    background: rgba(25, 25, 25, 0.5);
  }
  .border {
    width: 4px;
    height: 60px;
    margin: 0 16px 0 40px;
    background: linear-gradient(137deg, #00ffa0 0%, #00d2ff 25%, #0064ff 100%);
  }
  .title {
    .sub {
      margin-bottom: 12px;
      font-size: 14px;
      font-weight: 300;
      color: #fff;
      letter-spacing: 2px;
      opacity: 0.65;
    }
    .main {
      font-size: 28px;
      font-weight: 500;
      color: #fff;
      letter-spacing: 2px;
    }
    .c {
      position: relative;
      left: -21px;
      padding-top: 15px;
      font-size: 16px;
      font-weight: 400;
      color: #fff;
    }
  }
  .arrow-group {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-left: auto;
    padding-top: 30px;
  }
  .arrow {
    display: flex;
    margin-right: 48px;
    transition: margin-right 0.3s ease-out;
    &.small {
      .horizontal {
        width: 40px;
      }
    }
    .horizontal {
      position: relative;
      top: 4px;
      right: -16px;
      width: 60px;
      height: 3px;
      background: #fff;
      transition: width 0.3s ease-out;
    }
    .right {
      width: 5px;
      height: 5px;
      padding: 3px;
      border: solid #fff 0;
      border-right-width: 3px;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }
  }
}
</style>
