/*
 * @Author: wei_jt@ecidi.com
 * @Date: 2019-11-01 11:45:47
 * @LastEditors: wei_jt@ecidi.com
 * @LastEditTime: 2022-01-28 17:34:31
 * @Description: app action types
 */
//刷新token
export const REFRESH_TOKEN = "refreshToken";
export const USER_LOGIN = "userLogin";
export const SOCIAL_LOGIN = "socialLogin";
export const BIND_USER = "bindUser";

//更新用户信息
export const UPDATE_USER = "updateUser";
// 系统登录
export const LOGIN = "login";
//获取系统资源
export const LOADING_INIT_DATA = "loadingInitData";
export const LOADING_USER_DATA = "loadingUserData";
export const GET_CLIENT_CONFIG = "getClientConfig";
export const GET_LANG = "getLang";
export const GET_LANGS = "getLangs";
export const GET_ENUMS = "getEnums";
export const GET_ENUM = "getEnum";
export const GET_BUTTONS = "getButtons";
export const GET_PERMISSIONS = "getPermissions";

export const GET_PORTALS = "getPortals";
export const CHANGE_PORTAL = "changePortal";
export const CLEAN_DATA = "cleanData";

//通用配置部分
export const PUT_CLIENT_CONFIG = "putClientConfig"; // 统一提交通用配置方法
export const GET_LATEST_CLIENT_CONFIG = "getLatestClientConfig"; // 获取最新额外配置
export const CHANGE_INTERFACE = "changeInterface";
export const CHANGE_WATERMARK = "changeWatermark";
export const TOGGLE_SIDEBAR = "toggleSideBar";
export const CLOSE_SIDEBAR = "closeSideBar";
export const TOGGLE_DEVICE = "toggleDevice";

//标签栏部分
export const ADD_VIEW = "addView";
export const ADD_VISITED_VIEW = "addVisitedView";
export const ADD_CACHED_VIEW = "addCachedView";
export const DEL_VIEW = "delView";
export const DEL_VISITED_VIEW = "delVisitedView";
export const DEL_CACHED_VIEW = "delCachedView";
export const DEL_OTHERS_VIEWS = "delOthersViews";
export const DEL_OTHERS_VISITED_VIEWS = "delOthersVisitedViews";
export const DEL_OTHERS_CACHED_VIEWS = "delOthersCachedViews";
export const DEL_ALL_VIEWS = "delAllViews";
export const DEL_ALL_VISITED_VIEWS = "delAllVisitedViews";
export const DEL_ALL_CACHED_VIEWS = "delAllCachedViews";
export const UPDATE_VISITED_VIEW = "updateVisitedView";

//消息部分
export const INIT_WEBSOCKET = "initWebsocket";
export const CHECK_UNREAD_MESSAGE = "checkUnReadMessage";

//青鸾需求部分
export const GET_COMMUNITY_USER_DATA = "GetCommunityUserData";
