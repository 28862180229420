/*
 * @Author: gao_m3
 * @Date: 2020-09-03 08:52:51
 * @LastEditors: zhong_m
 * @LastEditTime: 2022-01-24 14:24:04
 * @Descripttion:
 */
import * as types from "./getterTypes";
import * as stateTypes from "../State/stateTypes";
import defaultSettings from "@/config";
import API from "@/config/api";
import { developerMode } from "../../config/app/advanced";

const {
  tagsView,
  topMenu,
  stripe,
  theme,
  logo,
  logoIconOffset,
  title,
  websiteLogo,
  multiPortal,
  language,
  copyright,
  timezone,
  dateFormat,
  watermarkPosi,
  pageWatermark,
  imgWatermark,
} = defaultSettings;
const getters = {
  //通用配置
  [types.PAGE_WATERMARK]: (state) => {
    return (
      state[stateTypes.WATERMARK_CONFIG][types.PAGE_WATERMARK] || pageWatermark
    );
  },
  [types.IMG_WATERMARK]: (state) => {
    return (
      state[stateTypes.WATERMARK_CONFIG][types.IMG_WATERMARK] || imgWatermark
    );
  },
  [types.WATERMARK_POSI]: (state) => {
    return (
      state[stateTypes.WATERMARK_CONFIG][types.WATERMARK_POSI] || watermarkPosi
    );
  },
  [types.WATERMARK_PREFIX]: (state) => {
    return state[stateTypes.WATERMARK_CONFIG].hasOwnProperty([
      types.WATERMARK_PREFIX,
    ])
      ? state[stateTypes.WATERMARK_CONFIG][types.WATERMARK_PREFIX]
      : state[stateTypes.CLIENT_NAME];
  },
  [types.WATERMARK_SUFFIX]: (state) => {
    //number转string
    return state[stateTypes.WATERMARK_CONFIG][types.WATERMARK_SUFFIX] + "";
  },

  [types.REAL_TIME_PUSH]: (state) => {
    return state[stateTypes.ADVANCED_CONFIG][types.REAL_TIME_PUSH];
  },

  [types.DEVELOPER_FLAG]: (state) => {
    return developerMode && state[stateTypes.DEVELOPER_FLAG];
  },

  [types.SOCKET_TYPE]: (state) => {
    return state[stateTypes.ADVANCED_CONFIG][types.SOCKET_TYPE];
  },
  [types.MAX_RECONNECT]: (state) => {
    return state[stateTypes.ADVANCED_CONFIG][types.MAX_RECONNECT];
  },
  [types.RECONNECT_INTERVAL]: (state) => {
    return state[stateTypes.ADVANCED_CONFIG][types.RECONNECT_INTERVAL];
  },
  [types.OPEN_REGISTER]: (state) => {
    return state[stateTypes.ADVANCED_CONFIG][types.OPEN_REGISTER];
  },
  [types.I18N]: (state) => {
    //toDo:是否在界面配置下
    return state[stateTypes.ADVANCED_CONFIG][types.I18N];
  },
  [types.BEHAVIOR_ANALYSIS]: (state) => {
    return state[stateTypes.ADVANCED_CONFIG][types.BEHAVIOR_ANALYSIS];
  },

  [types.LANGUAGE]: (state) => {
    return state[stateTypes.INTERFACE_CONFIG][types.LANGUAGE] || language;
  },
  [types.COPYRIGHT]: (state) => {
    return state[stateTypes.INTERFACE_CONFIG][types.COPYRIGHT] || copyright;
  },
  [types.FOOTER_VIEW]: (state) => {
    return !!state[stateTypes.INTERFACE_CONFIG][types.FOOTER_VIEW] || false;
  },
  [types.THEME]: (state) => {
    return state[stateTypes.INTERFACE_CONFIG][types.THEME] || theme;
  },
  [types.LOGO]: (state) => {
    let b =
      state[stateTypes.INTERFACE_CONFIG][types.LOGO] &&
      state[stateTypes.INTERFACE_CONFIG][types.LOGO].match(/\/sys-storage/);
    return b
      ? API.VUE_APP_BASE_API + state[stateTypes.INTERFACE_CONFIG][types.LOGO]
      : logo;
  },
  [types.LOGO_ICON_OFFSET]: (state) => {
    return (
      state[stateTypes.INTERFACE_CONFIG][types.LOGO_ICON_OFFSET] ||
      logoIconOffset ||
      0
    );
  },
  [types.WEBSITE_LOGO]: (state) => {
    let b =
      state[stateTypes.INTERFACE_CONFIG][types.WEBSITE_LOGO] &&
      state[stateTypes.INTERFACE_CONFIG][types.WEBSITE_LOGO].match(
        /\/sys-storage/
      );
    return b
      ? API.VUE_APP_BASE_API +
          state[stateTypes.INTERFACE_CONFIG][types.WEBSITE_LOGO]
      : websiteLogo;
  },
  [types.TAGS_VIEW]: (state) => {
    return state[stateTypes.INTERFACE_CONFIG][types.TAGS_VIEW] || tagsView;
  },
  [types.STRIPE]: (state) => {
    return !!state[stateTypes.INTERFACE_CONFIG][types.STRIPE] || stripe;
  },
  [types.TIMEZONE]: (state) => {
    return state[stateTypes.INTERFACE_CONFIG][types.TIMEZONE] || timezone;
  },
  [types.DATE_FORMAT]: (state) => {
    return state[stateTypes.INTERFACE_CONFIG][types.DATE_FORMAT] || dateFormat;
  },
  [types.WEBSITE_TEXT]: (state) => {
    return state[stateTypes.INTERFACE_CONFIG][types.WEBSITE_TEXT] || title;
  },
  [types.TOP_MENU]: (state) => {
    return state[stateTypes.INTERFACE_CONFIG][types.TOP_MENU] || topMenu;
  },
  [types.MULTI_PORTAL]: (state) => {
    //toDo:是否在界面配置下
    return multiPortal;
  },
  [types.PERMISSIONS]: (state) => {
    let arr = [];
    for (let permission in state[stateTypes.PERMISSION]) {
      arr = [...arr, ...state[stateTypes.PERMISSION][permission]];
    }
    return arr;
  },
  [types.UNCHECKED_PWD]: (state) => {
    return (
      state.accountConfig.mandatoryModify == 2 &&
      (state.user.isInitPwd !== false || state.user.pwdIsExpired === true)
    );
  },
  //根据name获得在池中的ws链接
  [types.WEBSOCEKT_IN_POOL]: (state) => {
    return (name) => {
      if (name == "") return {};
      return state[stateTypes.WEBSOCKET_POOL].find((e) => e.name == name) ?? {};
    };
  },
};
export default getters;
