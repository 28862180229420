/*
 * @Author: wei_jt@ecidi.com
 * @Date: 2019-11-01 15:13:25
 * @LastEditors: wei_jt@ecidi.com
 * @LastEditTime: 2022-01-28 17:38:00
 * @Description: app state types
 */
export const LANG = "language"; //多语言配置文件存储
export const ENUM = "enum"; //通用枚举
export const SIDE_BAR = "sidebar"; //侧边栏
export const DEVICE = "device"; //设备类型
export const SIDE_BAR_LOGO = "sidebarLogo"; //侧边栏logo
//应用配置
export const AUTH_CONFIG = "authConfig";
export const INTERFACE_CONFIG = "interfaceConfig";
export const LOGIN_STRATEGY = "loginStrategy";
export const ACCOUNT_CONFIG = "accountConfig";
export const WATERMARK_CONFIG = "watermarkConfig";
export const ADVANCED_CONFIG = "advancedConfig"; // 高级设置
export const VISITED_VIEWS = "visitedViews"; //页面记录
export const CACHED_VIEWS = "cachedViews";
export const LATEST_CLIENT_CONFIG = "latestClientConfig"; // 最新的clientConfig
export const ROUTES = "routes";

export const USER = "user"; //用户
export const PERMISSION = "permission"; //用户权限
export const BUTTONS = "buttons"; //系统所有权限
export const PERMISSION_QUEUE = "permissionQueue"; //鉴权队列

export const PORTALS = "portals"; //门户
export const PORTAL = "portal"; //当前门户

export const HAS_UNREAD_MESSAGE = "hasUnReadMessage"; //有未读消息
export const RECEIVED_MESSAGE = "receivedMessage"; //有未读消息
export const CLIENT_NAME = "clientName"; //应用名称

export const CURRENT_LANG = "currentLang"; //当前语种
export const WEBSOCKET_POOL = "websocketPool"; //ws连接池

export const DEVELOPER_FLAG = "developerFlag"; // 是否是开发者模式

//青鸾需求部分
export const LOGINDIALOGVISIBLE = "loginDialogVisible";

export const REMESSAGE = "reMessage"; //新消息
export const UNREADMESSAGE = "unreadMessage"; //新消息
export const UNREADMSGNUM = "unreadMsgNum"; //新消息数量

export const TASK = "task"; //新手任务
export const ACHV = "achv"; //成就
