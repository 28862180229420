/*
 * @Author: zhong_m
 * @Date: 2021-12-06 11:33:20
 * @LastEditTime: 2022-06-08 09:13:18
 * @LastEditors: chen_yt
 * @Description: 开发配置，用于开发调试
 * @FilePath: \central-system\src\config\app\develop.js
 */

module.exports = {
  CLIENT: "qingluan", // 应用公钥
  CLIENT_SECRET: "qingluan_secret", // 应用私钥
  prefix: "qingluan_", //缓存前缀
  custom_flow_prefix: "fawkes_custom_flow_", // 自定义流程前缀
  baseProxy: "api", // 接口代理节点
  // clientURL:
  //   process.env.NODE_ENV === "development"
  //     ? "http://10.215.142.66:31109"
  //     : "https://qingluan.hdec.com",
  clientURL:"https://qingluan.hdec.com",
  /**
   * @type {boolean} true | false
   * @description 是否加载本地 路由|权限
   */
  localRoute: false,

  /**
   * @type {string}
   * @description 密码加密方式:MD5、SM4、SM2
   */
  pwdEncrypType: "SM4",

  /**
   * @type {boolean} true | false
   * @description 请求加密
   */
  requestEncrypt: false,

  /**
   * @type {boolean} true | false
   * @description 响应加密
   */
  responseEncrypt: false,

  /**
   * @type {string} 支持SM4、SM2、AES、DES、RSA
   * @description 接口参数加密方式
   */
  interfaceEncryptType: "SM4",

  /**
   * @type {boolean} true | false
   * @description 是否开启多门户
   */
  multiPortal: false,

  /**
   * @type {boolean} true | false
   * @description 缓存隔离
   */
  storageIsolation: false,

  /**
   * @type {Number}
   * @description 校验服务器时间戳间隔
   */
  tsDvalue: 180000,

  /**
   * @type {Number}
   * @description 签名有效期，最短为180
   */
  TTL: 18000,

  /**
   * @type {boolean}  true | false
   * @description 是否为升级模式
   */
  updatingMode: false,

  //代理设置,开发环境默认baseUrl为/api，如需代理至其它地址，请在默认代理前设置路径
  //修改后需重启项目
  proxy: {
    // '/api/xxx': {
    //   target: `http://****/`,
    //   changeOrigin: true,
    //   pathRewrite: {
    //     '/api': ''
    //   },
    "/api": {
      // target: "https://apigateway.hdec.com/fawkes-new/local/api",
      target: "https://qingluan.hdec.com/api",
      changeOrigin: true,
      pathRewrite: {
        "/api": "",
      },
    },
    "/filePreview": {
      target: "https://apigateway.hdec.com/fawkes-new/local/filePreview",
      changeOrigin: true,
      pathRewrite: {
        "/filePreview": "",
      },
    },
    "/sub_app_wp/": {
      target: "https://apigateway.hdec.com/fawkes-new/staging/sp",
      changeOrigin: true,
      pathRewrite: {
        "/sub_app_wp": "",
      },
    },
  },
};
