/*
 * @Author: wei_jt@ecidi.com
 * @Date: 2019-11-01 11:45:47
 * @LastEditors: wei_jt@ecidi.com
 * @LastEditTime: 2022-01-28 17:37:28
 * @Description: app mutation types
 */

export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const CLOSE_SIDEBAR = "CLOSE_SIDEBAR";
export const TOGGLE_DEVICE = "TOGGLE_DEVICE";
//tagview相关
export const ADD_VISITED_VIEW = "ADD_VISITED_VIEW";
export const ADD_CACHED_VIEW = "ADD_CACHED_VIEW";
export const DEL_VISITED_VIEW = "DEL_VISITED_VIEW";
export const DEL_CACHED_VIEW = "DEL_CACHED_VIEW";
export const DEL_OTHERS_VISITED_VIEWS = "DEL_OTHERS_VISITED_VIEWS";
export const DEL_OTHERS_CACHED_VIEWS = "DEL_OTHERS_CACHED_VIEWS";
export const DEL_ALL_VISITED_VIEWS = "DEL_ALL_VISITED_VIEWS";
export const DEL_ALL_CACHED_VIEWS = "DEL_ALL_CACHED_VIEWS";
export const CLEAN_VIEWS = "CLEAN_VIEWS";
export const UPDATE_VISITED_VIEW = "UPDATE_VISITED_VIEW";

export const SET_LATEST_CLIENT_CONFIG = "SET_LATEST_CLIENT_CONFIG"; //获取最新配置
export const CHANGE_INTERFACE = "CHANGE_INTERFACE"; //修改自定义设置
export const CHANGE_WATERMARK = "CHANGE_WATERMARK";
export const SET_CLIENT_CONFIG = "SET_CLIENT_CONFIG"; //更新应用配置
export const SET_LOCAL_LANG = "SET_LOCAL_LANG"; //加载本地国际化
export const SET_ONLINE_LANG = "SET_ONLINE_LANG"; //读取在线国际化
export const SET_ENUM = "SET_ENUM"; //增加enum枚举值
export const SET_WATERMARK = "SET_WATERMARK"; //新增水印

export const CLEAN_STORAGE = "CLEAN_STORAGE";
export const SET_USER = "SET_USER"; //暂存人员信息
export const SET_PERMISSION = "SET_PERMISSION"; //增加权限
export const CLEAN_PERMISSION = "CLEAN_PERMISSION"; //清除权限
export const UPDATE_PERMISSION_QUEUE = "UPDATE_PERMISSION_QUEUE"; //更新权限请求队列
export const REMOVE_PERMISSION_QUEUE = "REMOVE_PERMISSION_QUEUE"; //移除已发送
export const CLEAN_PERMISSION_QUEUE = "CLEAN_PERMISSION_QUEUE"; //清除权限
export const SET_PORTALS = "SET_PORTALS"; //设置门户列表
export const UPDATE_PORTAL = "UPDATE_PORTAL"; //更新当前门户
export const SET_ROUTES = "SET_ROUTES"; //更新路由
export const SET_BUTTONS = "SET_BUTTONS"; //更新路由
export const CLEAN_ROUTE = "CLEAN_ROUTE"; //清空路由数据

export const SET_CURRENT_LANG = "SET_CURRENT_LANG"; //设置当前的语种
export const CREATE_WEBSOCKET_POOL_CLIENT = "CREATE_WEBSOCKET_POOL_CLIENT"; //全局连接池新增对象
export const UPDATE_WEBSOCKET_POOL_CLIENT = "UPDATE_WEBSOCKET_POOL_CLIENT"; //全局连接池更新对象
export const DEL_WEBSOCKET_POOL_CLIENT = "DEL_WEBSOCKET_POOL_CLIENT"; //根据name从全局连接池中删除ws
export const CLEAN_WEBSOCKET_POOL = "CLEAN_WEBSOCKET_POOL"; //清空维护的全局连接池
export const UPDATE_HAS_UNREAD_MESSAGE = "UPDATE_HAS_UNREAD_MESSAGE"; //更新是否有消息未读
export const UPDATE_RECEIVED_MESSAGE = "UPDATE_RECEIVED_MESSAGE"; //更新是否接收到消息

// 修改sessionstorage的开发者模式标识
export const SET_DEVELOPER_FLAG = "setDeveloperFlag";

//青鸾需求部分
export const SET_LOGIN_VISIBLE = "SET_LOGIN_VISIBLE";
export const RELOAD_MESSAGE = "RELOAD_MESSAGE"; //更新消息
export const RELOAD_UNREADMESSAGE = "RELOAD_UNREADMESSAGE"; //更新消息刷新标识
export const RELOAD_UNREADMSGNUM = "RELOAD_UNREADMSGNUM"; //更新消息刷新数量
export const RELOAD_TASK = "RELOAD_TASK"; //更新任务
export const RELOAD_ACHV = "RELOAD_ACHV"; //更新成就
