/*
 * @Author: gao_m3
 * @Date: 2020-09-03 08:52:51
 * @LastEditors: chen_yt
 * @LastEditTime: 2022-01-24 14:10:17
 * @Descripttion: 非环境变量，环境相关配置移步根目录.env文件
 */
const app = require("./app");
const microApp = require("./microApp");

module.exports = {
  ...app,
  ...microApp,
};
