/*
 * @Author: zhong_m
 * @Date: 2021-12-06 11:33:20
 * @LastEditTime: 2022-01-24 14:10:27
 * @LastEditors: chen_yt
 * @Description: 开发配置，用于开发调试
 * @FilePath: \central-system\src\config\develop.js
 */
const develop = require("./develop");
const advanced = require("./advanced");
const general = require("./general");

const config = {
  ...develop,
  ...advanced,
  ...general,
  /**
   * @type {boolean} true | false
   * @description 是否加载本地 路由|权限
   */
  localRoute: process.env.VUE_APP_LOCAL_ROUTE === "true",

  /**
   * @type {boolean} true | false
   * @description 请求加密
   */
  requestEncrypt: process.env.VUE_APP_REQUEST_ENCRYPT === "true",

  /**
   * @type {boolean} true | false
   * @description 响应加密
   */
  responseEncrypt: process.env.VUE_APP_RESPONSE_ENCRYPT === "true",

  /**
   * @type {boolean} true | false
   * @description 是否开启多门户
   */
  multiPortal: process.env.VUE_APP_MULTIPORTAL === "true",
};

if (process.env.NODE_ENV === "development") {
  Object.assign(config, develop);
}

module.exports = config;
