<template>
    <div class="product">
        <div class="eg-name">PRODUCT RESEARCH</div>
        <div class="name">产品研发</div>
        <div class="list">
            <router-link
                    to="/productTemp/BIM"
                    class="item bimC active"
                    @mouseenter="mouseenter"
            >
                <img
                        class="i bimC"
                        alt="product-logo"
                        src="@/assets/home/product/bim-center.png"
                />
                <div class="n">BIM中台</div>
                <div class="detail">
                    <p class="p">
                        BIM中台是一款采用微服务架构分层设计的中台产品,规范BIM模型和数据,形成面向业务需求的高效场景化模型和数据服务能力,实现工程项目管理过程中BIM数据全周期集成及数据交换。
                    </p>
                </div>
            </router-link>
            <router-link
                    to="/productTemp/BIM"
                    class="item bimF"
                    @mouseenter="mouseenter"
            >
                <img
                        class="i bimF"
                        alt="product-logo"
                        src="@/assets/home/product/bim-simulation.png"
                />
                <div class="n">BIM仿真</div>
                <div class="detail">
                    <p class="p">
                        BIM仿真系统是一款具有完全自主知识产权的BIM基础应用软件，可快速汇聚和展示基础模型、工程模型和计算模型，同时针对工程规划、设计、施工阶段，提供场景规划仿真、模型优化设计分析和施工仿真模拟等核心功能，覆盖工程行业多领域应用。
                    </p>
                </div>
            </router-link>
            <router-link
                    to="/productTemp/twin/cyber-twin"
                    class="item r"
                    @mouseenter="mouseenter"
            >
                <img class="i r" alt="product-logo" src="@/assets/home/product/r.png"/>
                <div class="n">孪图</div>
                <div class="detail">
                    <p class="p">
                        CyberTwin数字孪生平台是一个集工程数据创建整合，数据加工和数据消费全流程的图形解决方案，结合云存储，云渲染、AI识别、数据中台等技术，整合图形数据全过程应用；分场景、分标准、分模块，全面覆盖工程全生命周期可视化应用诉求，赋能数字工程建设。
                    </p>
                </div>
            </router-link>
            <router-link
                    to="/productTemp/shiwu"
                    class="item s"
                    @mouseenter="mouseenter"
            >
                <img class="i" alt="product-logo" src="@/assets/home/product/s.png"/>
                <div class="n">石坞</div>
                <div class="detail">
                    <p class="p">
                        石坞融数据管理平台，聚焦工程融数据，以大数据为基底，提供行业全领域智能数据治理能力，帮助工程企业消除数据孤岛，加快数据变现，助力数字化转型。
                    </p>
                </div>
            </router-link>
            <router-link
                    to="/productTemp/yunpeng"
                    class="item y"
                    @mouseenter="mouseenter"
            >
                <img class="i" alt="product-logo" src="@/assets/home/product/y.png"/>
                <div class="n">云鹏</div>
                <div class="detail">
                    <p class="p">
                        华东院物联网平台以端边交汇处为切入点，以平台为核心的软硬件一体化体系，为众多业务系统提供稳定数据来源，服务设备全生命周期管理，支撑业务创新发展，提供强大的开放能力，支撑快速构建各类物联网应用。
                    </p>
                </div>
            </router-link>
            <router-link
                    to="/productTemp/fawkes"
                    class="item fawkes"
                    @mouseenter="mouseenter"
            >
                <img
                        class="i"
                        alt="product-logo"
                        src="@/assets/home/product/fawkes.png"
                />
                <div class="n">凤翎</div>
                <div class="detail">
                    <p class="p">
                        凤翎快速开发平台是一套基于主流微服务技术，采用开源框架+核心自研模式开发的，底层开发平台。平台有别于传统开发平台，沉淀了大量模板和配置功能，提供物联网、图形引擎、GIS等专业引擎，旨在助推行业应用落地，促进工程行业技术生态建设，赋能开发者，赋能用户，传递开发者文化。
                    </p>
                </div>
            </router-link>
            <router-link
                    to="/productTemp/ai"
                    class="item ai"
                    @mouseenter="mouseenter"
            >
                <img
                        class="i"
                        alt="product-logo"
                        src="@/assets/home/product/wendao.png"
                />
                <div class="n">闻道</div>
                <div class="detail">
                    <p class="p">
                        AI开发平台提供涵盖数据标注、训练、部署及发布的全流程开发环境和配套工具资源;简化深度学习开发流程,降低用户学习门槛,助力项目高效开发及部署,推动企业从数字化到智能化转型,提升生产组织管理效率。
                    </p>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script setup>
    const mouseenter = (e) => {
        const target = e.target;
        const ac = document.querySelector(".product .list .active");
        ac && ac.classList.remove("active");
        target.classList.add("active");
    };
</script>

<style lang="scss" scoped>
    .product {
        position: relative;
        width: 100%;
        height: 600px;
        padding-top: 120px;

        .eg-name {
            position: absolute;
            top: 80px;
            left: 50%;
            width: 550px;
            margin-left: -275px;
            font-size: 48px;
            font-weight: bold;
            letter-spacing: 1px;
            background: linear-gradient(
                            180deg,
                            #f6f7f9 0%,
                            rgba(238, 238, 238, 0.02) 100%
            );
            background-clip: text;
            color: transparent;
            text-align: center;
        }

        .name {
            position: relative;
            width: 160px;
            margin: 0 auto 50px;
            font-size: 36px;
            font-weight: 600;
            letter-spacing: 2px;
            text-align: center;
        }
    }

    .list {
        display: flex;
        justify-content: space-between;
        width: 1200px;
        height: 450px;
        margin: 0 auto;

        .item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 136px;
            height: 420px;
            transition: width 0.3s, background-image 0.3s;
            overflow: hidden;
            text-decoration: none;

            &.fawkes {
                background: url("@/assets/home/product/bg_fl.png");
            }

            &.r {
                background: url("@/assets/home/product/bg_s.png");
            }

            &.s {
                background: url("@/assets/home/product/bg_y.png");
            }

            &.y {
                background: url("@/assets/home/product/bg_yp.png");
            }

            &.bimF {
                background: url("@/assets/home/product/bg_bim_f.png");
            }
            &.bimC {
                background: url("@/assets/home/product/bg_fawkes.png");
            }
            &.ai {
                background: url("@/assets/home/product/bg_r.png");
            }
            &.active {
                width: 320px;

                &.fawkes {
                    background: url("@/assets/home/product/bg_fl_hover.png");
                }

                &.r {
                    background: url("@/assets/home/product/bg_s_hover.png");
                }

                &.s {
                    background: url("@/assets/home/product/bg_y_hover.png");
                }

                &.y {
                    background: url("@/assets/home/product/bg_yp_hover.png");
                }

                &.bimC {
                    background: url("@/assets/home/product/bg_bim_hover.png");
                }
                &.bimF {
                    background: url("@/assets/home/product/bg_bim_f_hover.png");
                }
                &.ai {
                    background: url("@/assets/home/product/bg_r_hover.png");
                }

                .detail {
                    width: 290px;
                    height: auto;

                    .p {
                        opacity: 1;
                    }
                }
            }

            .i {
                position: relative;
                top: -15px;
                width: 52px;
                height: 52px;
            }

            .n {
                width: 52px;
                margin-bottom: 30px;
                font-size: 20px;
                font-weight: 500;
                color: #fff;
                letter-spacing: 5px;
            }

            .detail {
                width: 100px;
                height: 0;
                font-size: 16px;
                font-weight: 300;
                color: #fff;
                line-height: 32px;
                overflow: hidden;
                transition: width 0.3s;

                .p {
                    width: 290px;
                    height: auto;
                    margin: 0;
                    opacity: 0;
                    transition: opacity 0.5s;
                }
            }
        }
    }
</style>
