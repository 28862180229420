/*
 * @Author: gao_m3
 * @Date: 2021-08-03 15:29:11
 * @LastEditors: wei_jt@ecidi.com
 * @LastEditTime: 2021-09-24 10:23:38
 * @Descripttion:
 */
function cssHelper(el, prototype) {
  for (let i in prototype) {
    el.style[i] = prototype[i];
  }
}
function watermark(val) {
  const waterWrapper = document.createElement("div");
  cssHelper(waterWrapper, {
    position: "fixed",
    top: "0px",
    right: "0px ",
    bottom: "0px",
    left: "0px",
    overflow: "hidden",
    display: "flex",
    "flex-wrap": "wrap",
    "z-index": "100",
    "pointer-events": "none",
  });
  waterWrapper.id = "watermark";
  const waterHeight = 100;
  const waterWidth = 180;
  const { clientWidth, clientHeight } =
    document.documentElement || document.body;
  const column = Math.ceil(clientWidth / waterWidth);
  const rows = Math.ceil(clientHeight / waterHeight);

  function createItem() {
    const item = document.createElement("div");
    item.innerHTML = val;
    cssHelper(item, {
      position: "absolute",
      top: `50px`,
      left: `50px`,
      fontSize: `16px`,
      color: "#000",
      lineHeight: 1.5,
      opacity: 0.1,
      transform: `rotate(-15deg)`,
      transformOrigin: "0 0",
      userSelect: "none",
      whiteSpace: "nowrap",
      overflow: "hidden",
    });
    return item;
  }
  for (let i = 0; i < column * rows; i++) {
    const wrap = document.createElement("div");
    cssHelper(
      wrap,
      Object.create({
        position: "relative",
        width: `${waterWidth}px`,
        height: `${waterHeight}px`,
        flex: `0 0 ${waterWidth}px`,
        overflow: "hidden",
      })
    );
    wrap.appendChild(createItem());
    waterWrapper.appendChild(wrap);
  }

  document.body.appendChild(waterWrapper);
}
function showWatermark(val) {
  var mark = document.getElementById("watermark");
  if (mark) {
    mark.style["z-index"] = 1000;
  } else {
    watermark(val);
  }
}
function hideWatermark() {
  var mark = document.getElementById("watermark");
  if (mark) {
    mark.style["z-index"] = -1;
  }
}
function deleteWatermark() {
  var mark = document.getElementById("watermark");
  if (mark) {
    document.body.removeChild(mark);
  }
}
export { watermark, showWatermark, hideWatermark, deleteWatermark };
