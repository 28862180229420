import { createApp } from "vue";
// import FawkesLib from 'fawkes-lib' //组件库
//全局导入element-plus
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import { LOADING_INIT_DATA } from "@/store/Action/actionTypes";
import ArcoVue from "@arco-design/web-vue";
import ArcoVueIcon from "@arco-design/web-vue/es/icon";
import App from "./App.vue";
import moment from 'moment-ecidi'
import router from "./router";
import "@arco-design/web-vue/dist/arco.css";
import {
  handleTime,
  getSrc,
  getImgSrc,
  escapeENUM,
  escapeTreeENUM,
  addVideoSignByFksToken,
} from "@/utils/util";
import { clientURL } from "@/config";

import dayjs from "dayjs";
dayjs.locale("zh-cn");
import VCalendar from "v-calendar";
import "v-calendar/style.css";
import store from "./store";

store.dispatch(LOADING_INIT_DATA);
const app = createApp(App).use(store);
// app.component('svg-icon', SvgIcon) //在线svg组件注册，本地模块可改为离线svg组件
// //svg载入
//
// const req = require.context('./assets/svg', true, /\.svg$/)
// const requireAll = (requireContext) =>requireContext.keys().map(requireContext)
// requireAll(req)
//fawkes组件库
// app.use(FawkesLib, {
//     preciseSearch: true,
//     hideSwitch: true
// })
app.use(ElementPlus);
app.use(ArcoVue);
app.use(ArcoVueIcon);
app.use(VCalendar, {
  masks: {
    title: "YYYY 年 MM 月",
    dayPopover: "MMMD日, WWW",
  },
});
app.use(router);
app.config.globalProperties.$moment = moment;
app.config.globalProperties.handleTime = handleTime
app.config.globalProperties.getSrc = getSrc;
app.config.globalProperties.getImgSrc = getImgSrc;
app.config.globalProperties.escapeENUM = escapeENUM;
app.config.globalProperties.escapeTreeENUM = escapeTreeENUM;
app.config.globalProperties.handleContent = addVideoSignByFksToken;
app.config.globalProperties.clientURL = clientURL;
app.mount("#app");
