/*
 * @Author: wei_jt@ecidi.com
 * @Date: 2019-11-07 09:36:15
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-09-06 11:27:00
 * @Description: 用户相关接口
 */
import request from "@/utils/request";
import store from "@/store";
/**查询用户菜单 */
export function getRouter() {
  return request({
    url: "/sys-user/user/menus",
    method: "get",
    params: {
      portalId: store.state.portal.id,
    },
  });
}

/**查询用户菜单下按钮 */
export function getPermissions(menuId) {
  return request({
    url: "/sys-user/user/buttons",
    method: "get",
    params: {
      menuId,
      portalId: store.state.portal.id,
    },
  });
}

/**获取所有门户 */
export function getPortals() {
  return request({
    url: "/sys-user/user/portals",
    method: "GET",
  });
}
export function getUserInfo(data) {
  return request({
    url: "/sys-user/userInfo",
    method: "get",
    params: data,
  });
}

export function getCommunityUserInfo(userName) {
  return request({
    url: "qingluan/user/information",
    method: "get",
    params: {
      userName: userName,
    },
  });
}
//新增浏览记录
export function setBrowseRecords(data) {
  return request({
    url: "/qingluan/browse",
    method: "post",
    pass: false,
    data,
  });
}
