<template>
  <div class="industry-research"></div>
</template>

<script setup></script>

<style lang="scss" scoped>
.industry-research {
  position: relative;
  width: 100%;
  padding-top: 350px;
}
</style>
