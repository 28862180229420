import Cookies from "js-cookie";
const TokenKey = "qingluan_access_token";
const User = "qingluan_user";

export function setToken(token, user) {
  Cookies.set(User, JSON.stringify(user));
  return Cookies.set(TokenKey, token);
}

export function getToken() {
  return Cookies.get(TokenKey);
}

export function getUser() {
  return JSON.parse(Cookies.get(User) || "{}");
}

export function removeToken() {
  return Cookies.remove(TokenKey);
}

export function removeUser() {
  return Cookies.remove(User);
}
