// import Cookies from 'js-cookie'
import * as types from "./mutationTypes";
import * as stateTypes from "../State/stateTypes";
import { isJSON } from "@/utils/util";
import { transDate, matchTimestampToFormat } from "@/utils/timezone";
// import { resetRouter } from "@/router";
import {
  watermark,
  showWatermark,
  hideWatermark,
  deleteWatermark,
} from "@/utils/watermark";
import storage from "@/utils/storage";
import deepmerge from "deepmerge";
const mutations = {
  //更新应用配置
  [types.SET_CLIENT_CONFIG]: (state, data) => {
    let additionalInformation = {};
    if (
      data.hasOwnProperty("additionalInformation") &&
      data.additionalInformation != "null" &&
      isJSON(data.additionalInformation)
    ) {
      // 对时间戳进行转换
      additionalInformation = JSON.parse(
        matchTimestampToFormat(data.additionalInformation)
      );
      // 对已经是对象的格式直接赋值不需要转换
    } else if (
      data.hasOwnProperty("additionalInformation") &&
      data.additionalInformation instanceof Object
    ) {
      additionalInformation = JSON.parse(
        JSON.stringify(data.additionalInformation)
      );
    }
    state[stateTypes.INTERFACE_CONFIG] = additionalInformation.hasOwnProperty(
      "interfaceConfig"
    )
      ? additionalInformation.interfaceConfig
      : {};
    let timezone = state[stateTypes.INTERFACE_CONFIG].timezone;
    if (timezone && timezone !== "null")
      storage.set("default_timezone", timezone); //获取配置中设置的时区
    transDate(new Date().getTime(), 1);
    state[stateTypes.AUTH_CONFIG] = additionalInformation.hasOwnProperty(
      "dataSafe"
    )
      ? additionalInformation.dataSafe
      : {};
    state[stateTypes.LOGIN_STRATEGY] = {
      ...state[stateTypes.LOGIN_STRATEGY],
      ...(additionalInformation?.loginStrategy || {}),
    };
    state[stateTypes.ACCOUNT_CONFIG] = additionalInformation.hasOwnProperty(
      "accountConfig"
    )
      ? additionalInformation.accountConfig
      : {};
    state[stateTypes.WATERMARK_CONFIG] = additionalInformation.hasOwnProperty(
      "watermarkConfig"
    )
      ? additionalInformation.watermarkConfig
      : {};
    state[stateTypes.ENUM].languages = additionalInformation.hasOwnProperty(
      "langConfig"
    )
      ? additionalInformation.langConfig
      : [];

    state[stateTypes.ADVANCED_CONFIG] = {
      ...state[stateTypes.ADVANCED_CONFIG],
      ...(additionalInformation?.advancedConfig || {}),
    };
    state[stateTypes.CLIENT_NAME] = data.clientName;
    state[stateTypes.LATEST_CLIENT_CONFIG] = data;
  },
  // 保存最新的LATEST_CLIENT_CONFIG供各组件使用
  [types.SET_LATEST_CLIENT_CONFIG]: (state, data) => {
    state[stateTypes.LATEST_CLIENT_CONFIG] = data;
  },
  //读取模块语言资源
  [types.SET_LOCAL_LANG]: (state, data) => {
    let lang = state[stateTypes.LANG][data.key]
      ? deepmerge(data.value, state[stateTypes.LANG][data.key])
      : data.value;
    state[stateTypes.LANG] = deepmerge(state[stateTypes.LANG], {
      [data.key]: lang,
    });
  },

  //全量更新语言资源
  [types.SET_ONLINE_LANG]: (state, data) => {
    const lang = deepmerge({}, data);
    Object.keys(lang).forEach((key) => {
      if (/G_/.test(key)) {
        lang.Global = deepmerge(lang.Global, lang[key]);
        delete lang[key];
      }
    });
    state[stateTypes.LANG] = deepmerge(state[stateTypes.LANG], lang);
  },

  //补充枚举列表
  [types.SET_ENUM]: (state, data) => {
    //assign 后者覆盖前者
    state[stateTypes.ENUM] = Object.assign({}, state[stateTypes.ENUM], data);
  },

  [types.CLEAN_STORAGE]: (state) => {
    storage.remove("user");
    storage.remove("auth_portal");
    storage.remove("passwordTip");
  },

  // [types.CLEAN_ROUTE]: (state) => {
  //   state[stateTypes.ROUTES] = [];
  //   resetRouter();
  // },

  //获取门户列表
  [types.SET_PORTALS]: (state, data) => {
    state[stateTypes.PORTALS] = [...data];
  },

  //更新当前门户
  [types.UPDATE_PORTAL]: (state, data) => {
    state[stateTypes.PORTAL] = deepmerge({}, data);
    storage.set("portal", JSON.stringify(data));
  },

  //更新路由
  [types.SET_ROUTES]: (state, data) => {
    state[stateTypes.ROUTES] = data;
  },

  //系统按钮
  [types.SET_BUTTONS]: (state, data = []) => {
    state[stateTypes.BUTTONS] = data;
  },

  //更新通用配置
  [types.TOGGLE_SIDEBAR]: (state, opened) => {
    if (typeof opened === "boolean") {
      state.sidebar.opened = opened;
    } else {
      state.sidebar.opened = !state.sidebar.opened;
    }
  },

  [types.CHANGE_INTERFACE]: (state, { key, value }) => {
    if (state[stateTypes.INTERFACE_CONFIG].hasOwnProperty(key)) {
      state[stateTypes.INTERFACE_CONFIG][key] = value;
    } else {
      state[stateTypes.INTERFACE_CONFIG] = deepmerge(
        { [key]: value },
        state[stateTypes.INTERFACE_CONFIG]
      );
    }
  },

  [types.CHANGE_WATERMARK]: (state, { key, value }) => {
    if (state[stateTypes.WATERMARK_CONFIG].hasOwnProperty(key)) {
      state[stateTypes.WATERMARK_CONFIG][key] = value;
    } else {
      state[stateTypes.WATERMARK_CONFIG] = deepmerge(
        { [key]: value },
        state[stateTypes.WATERMARK_CONFIG]
      );
    }
    if (key == "pageWatermark") {
      if (value) {
        //重现水印
        showWatermark(storage.get("watermark"));
      } else {
        hideWatermark();
      }
    }
  },

  [types.SET_WATERMARK]: (state, user) => {
    deleteWatermark();
    let suffix = "";
    switch (state.watermarkConfig.watermarkSuffix) {
      case 0:
        break;
      case 1:
        suffix = user.userName;
        break;
      default:
        suffix = user.userFullname;
    }
    storage.set(
      "watermark",
      state.watermarkConfig.watermarkPrefix + "@" + suffix
    );
    if (state.watermarkConfig.pageWatermark) {
      watermark(storage.get("watermark"));
    }
  },

  [types.CLOSE_SIDEBAR]: (state, withoutAnimation) => {
    // Cookies.set( 'sidebarStatus', 0 )
    state.sidebar.opened = false;
    state.sidebar.withoutAnimation = withoutAnimation;
  },

  [types.TOGGLE_DEVICE]: (state, device) => {
    state.device = device;
  },

  /**
   * tag相关
   * visited记录tag标签
   * cached记录keep-alive情况
   * activePath用于记录实际激活的tag
   */
  [types.ADD_VISITED_VIEW]: (state, view) => {
    //当activeMenu存在时，tag激活对应的路径
    view.activePath = (view.meta && view.meta.activeMenu) || view.path;
    view.title = view.title || "no-name";
    if (
      state[stateTypes.VISITED_VIEWS].some(
        (v) => v.activePath === view.activePath
      )
    ) {
      return false;
    }
    state[stateTypes.VISITED_VIEWS] = [
      ...state[stateTypes.VISITED_VIEWS],
      view,
    ];
  },

  [types.ADD_CACHED_VIEW]: (state, view) => {
    if (state[stateTypes.CACHED_VIEWS].includes(view.name)) {
      return false;
    }
    if (
      !(view.meta && view.meta.config && view.meta.config.includes("noCache"))
    ) {
      state[stateTypes.CACHED_VIEWS].push(view.name);
    }
  },

  [types.DEL_VISITED_VIEW]: (state, view) => {
    for (const [i, v] of state[stateTypes.VISITED_VIEWS].entries()) {
      if (v.path === view.path) {
        state[stateTypes.VISITED_VIEWS].splice(i, 1);
        break;
      }
    }
    //防止在激活子应用后不触发computed
    state[stateTypes.VISITED_VIEWS] = [...state[stateTypes.VISITED_VIEWS]];
  },

  [types.DEL_CACHED_VIEW]: (state, view) => {
    const index = state[stateTypes.CACHED_VIEWS].indexOf(view.name);
    index > -1 && state[stateTypes.CACHED_VIEWS].splice(index, 1);
  },

  [types.DEL_OTHERS_VISITED_VIEWS]: (state, view) => {
    state[stateTypes.VISITED_VIEWS] = state[stateTypes.VISITED_VIEWS].filter(
      (v) => {
        return (
          (v.meta && v.meta.config && v.meta.config.includes("affix")) ||
          v.path === view.path
        );
      }
    );
  },

  [types.DEL_OTHERS_CACHED_VIEWS]: (state, view) => {
    const index = state[stateTypes.CACHED_VIEWS].indexOf(view.name);
    if (index > -1) {
      state[stateTypes.CACHED_VIEWS] = state[stateTypes.CACHED_VIEWS].slice(
        index,
        index + 1
      );
    } else {
      // if index = -1, there is no cached tags
      state[stateTypes.CACHED_VIEWS] = [];
    }
  },

  [types.DEL_ALL_VISITED_VIEWS]: (state) => {
    // keep affix tags
    const affixTags = state[stateTypes.VISITED_VIEWS].filter(
      (tag) => tag.meta && tag.meta.config && tag.meta.config.includes("affix")
    );
    state[stateTypes.VISITED_VIEWS] = affixTags;
  },

  [types.DEL_ALL_CACHED_VIEWS]: (state) => {
    state[stateTypes.CACHED_VIEWS] = [];
  },

  [types.CLEAN_VIEWS]: (state) => {
    // not keep affix tags
    state[stateTypes.VISITED_VIEWS] = [];
    state[stateTypes.CACHED_VIEWS] = [];
  },

  [types.UPDATE_VISITED_VIEW]: (state, view) => {
    //当activeMenu存在时，tag激活对应的路径
    view.activePath = (view.meta && view.meta.activeMenu) || view.path;
    state[stateTypes.VISITED_VIEWS].find((v) => {
      if (v.activePath === view.activePath) {
        v = Object.assign(v, view);
        return true;
      }
    });
  },

  //用户信息
  [types.SET_USER]: (state, user) => {
    state[stateTypes.USER] = deepmerge({}, user);
    storage.set("user", JSON.stringify(user));
  },

  //权限信息
  [types.SET_PERMISSION]: (state, data) => {
    state[stateTypes.PERMISSION] = deepmerge(
      { [data.key]: data.value },
      state[stateTypes.PERMISSION]
    );
  },

  [types.CLEAN_PERMISSION]: (state) => {
    state[stateTypes.PERMISSION] = {};
  },

  [types.UPDATE_PERMISSION_QUEUE]: (state, data) => {
    if (data instanceof Array) {
      let array = Array.from(state[stateTypes.PERMISSION_QUEUE]);
      array.push(...data);
      state[stateTypes.PERMISSION_QUEUE] = new Set(array);
    } else {
      state[stateTypes.PERMISSION_QUEUE].add(data);
    }
  },

  [types.REMOVE_PERMISSION_QUEUE]: (state, code) => {
    state[stateTypes.PERMISSION_QUEUE].delete(code);
  },

  [types.CLEAN_PERMISSION_QUEUE]: (state, code) => {
    state[stateTypes.PERMISSION_QUEUE] = new Set();
  },

  //设置现在的语言
  [types.SET_CURRENT_LANG]: (state, data) => {
    state[stateTypes.CURRENT_LANG] = data;
  },

  //创建维护的全局连接池
  [types.CREATE_WEBSOCKET_POOL_CLIENT]: (state, stomp) => {
    if (
      !state[stateTypes.WEBSOCKET_POOL].some((v) => {
        return v.name === stomp.name;
      })
    ) {
      state[stateTypes.WEBSOCKET_POOL].push(stomp);
    }
  },

  //更新维护的全局连接池
  [types.UPDATE_WEBSOCKET_POOL_CLIENT]: (state, stomp) => {
    state[stateTypes.WEBSOCKET_POOL].some((v) => {
      if (v.name === stomp.name) {
        v = Object.assign(v, stomp);
        return true;
      }
    });
  },

  //删除维护的全局连接池
  [types.DEL_WEBSOCKET_POOL_CLIENT]: (state, name) => {
    state[stateTypes.WEBSOCKET_POOL] = state[stateTypes.WEBSOCKET_POOL].filter(
      (client) => {
        if (client.name === name) {
          client.activeEnd();
          return false;
        }
        return true;
      }
    );
  },

  //清空维护的全局连接池
  [types.CLEAN_WEBSOCKET_POOL]: (state) => {
    state[stateTypes.WEBSOCKET_POOL].forEach((client) => {
      client.activeEnd();
    });
    state[stateTypes.WEBSOCKET_POOL] = [];
  },

  //更新是否有消息未读
  [types.UPDATE_HAS_UNREAD_MESSAGE]: (state, data) => {
    state[stateTypes.HAS_UNREAD_MESSAGE] = data;
  },

  //更新新消息标志
  [types.UPDATE_RECEIVED_MESSAGE]: (state, data) => {
    state[stateTypes.RECEIVED_MESSAGE] = data;
  },
  /**
   * 修改开发者模式标识
   */
  [types.SET_DEVELOPER_FLAG](state, flag) {
    state[stateTypes.DEVELOPER_FLAG] = flag;
    sessionStorage.setItem("developerFlag", flag);
  },

  //青鸾需求部分
  //打开登录弹窗
  [types.SET_LOGIN_VISIBLE]: (state, data) => {
    state[stateTypes.LOGINDIALOGVISIBLE] = data;
  },
  //更新消息刷新标识
  [types.RELOAD_MESSAGE]: (state, data) => {
    state[stateTypes.REMESSAGE] = data;
  },
  //更新未读消息刷新标识
  [types.RELOAD_UNREADMESSAGE]: (state, data) => {
    state[stateTypes.UNREADMESSAGE] = data;
  },
  //更新未读消息数量
  [types.RELOAD_UNREADMSGNUM]: (state, data) => {
    state[stateTypes.UNREADMSGNUM] = parseInt(data);
    storage.set("unreadMsg", parseInt(data));
  },
  //更新任务标识
  [types.RELOAD_TASK]: (state, data) => {
    state[stateTypes.TASK] = data;
  },
  //更新成就标识
  [types.RELOAD_ACHV]: (state, data) => {
    state[stateTypes.ACHV] = data;
  },
};

export default mutations;
