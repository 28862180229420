/*
 * @Author: your name
 * @Date: 2022-01-04 09:52:13
 * @LastEditTime: 2022-01-20 17:49:05
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \central-system\src\store\index.js
 */
// import Vue from "vue";
import Vuex from "vuex";
import state from "./State/states";
import mutations from "./Mutation/mutations";
import actions from "./Action/actions";
import getters from "./Getter/getters";
// Vue.use(Vuex);
const appStore = {
  state,
  mutations,
  actions,
  getters,
  modules: {},
};
const store = new Vuex.Store(appStore);
// function getSubModules() {
//   const reqs = require.context("@/modules", true, /store\/index\.js$/, "lazy");
//   for (let k = 0; k < reqs.keys().length; k++) {
//     let path = reqs.keys()[k];
//     reqs(path)
//       .then((req) => {
//         req.default && store.registerModule(req.default.name, req.default);
//       })
//       .catch(() => {});
//   }
// }
// getSubModules();

export default store;
