/*
 * @Author: gao_m3
 * @Date: 2020-09-03 08:52:51
 * @LastEditors: zhong_m
 * @LastEditTime: 2022-01-20 16:12:11
 * @Descripttion:
 */
//主题色
export const THEME = "theme";
//系统语言
export const LANGUAGE = "language";
//菜单列表
export const TAGS_VIEW = "tagsView";
//导航模式
export const TOP_MENU = "topMenu";
//logoUrl
export const LOGO = "logo";
export const LOGO_ICON_OFFSET = "logoIconOffset";
//斑马纹
export const STRIPE = "stripe";
//站点名称
export const WEBSITE_TEXT = "websiteText";
//站点logo
export const WEBSITE_LOGO = "websiteLogo";
//多门户模式
export const MULTI_PORTAL = "multiPortal";
//站点授权
export const COPYRIGHT = "copyright";
//底部栏
export const FOOTER_VIEW = "footerView";
//权限
export const PERMISSIONS = "permissions";
//密码
export const UNCHECKED_PWD = "unCheckedPWD";
//时区
export const TIMEZONE = "timezone";
//日期格式
export const DATE_FORMAT = "dateFormat";
//国际化
export const I18N = "i18n";
//实时推送
export const REAL_TIME_PUSH = "realTimePush";
// socket连接类型
export const SOCKET_TYPE = "socketType";
// 最大重连数量
export const MAX_RECONNECT = "maxReconnect";
// 重连间隔
export const RECONNECT_INTERVAL = "reconnectInterval";
// 开启注册
export const OPEN_REGISTER = "openRegister";
// 登录验证类型
// export const CAPTCHA_TYPE = 'captchaType'
// 用户行为采集
export const BEHAVIOR_ANALYSIS = "behaviorAnalysis";
//全局水印
export const PAGE_WATERMARK = "pageWatermark";
//局部水印
export const IMG_WATERMARK = "imgWatermark";
//水印位置
export const WATERMARK_POSI = "watermarkPosi";
//水印前置
export const WATERMARK_PREFIX = "watermarkPrefix";
//水印后置
export const WATERMARK_SUFFIX = "watermarkSuffix";
//根据name获得在池中的ws链接
export const WEBSOCEKT_IN_POOL = "websocketInPool";
// 是否是开发者模式
export const DEVELOPER_FLAG = "developerFlag";
