import HmacSHA1 from "crypto-js/hmac-sha1";
import Base64 from "crypto-js/enc-base64";
import { CLIENT, CLIENT_SECRET, TTL } from "@/config/app/develop";
import moment from "moment-ecidi";
import storage from "./storage";
import { getTs } from "@/api/app";
import { nextTick } from "vue";
import Prism from "prismjs";
// import { nodePermission } from "@/permission";
import API from "@/config/api";
/*
 * @Author: wei_jt@ecidi.com
 * @Date: 2019-11-01 16:43:30
 * @LastEditors: zhong_m
 * @LastEditTime: 2022-01-10 14:35:17
 * @Description: 工具类，根据功能划分
 */
//判断数据是否为json
export function isJSON(str) {
  if (typeof str == "string") {
    try {
      let obj = JSON.parse(str);
      if (typeof obj == "object" && obj) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }
}

export function isEmpty(val) {
  // null or undefined
  if (val == null) return true;

  if (typeof val === "boolean") return false;

  if (typeof val === "number") return !val;

  if (val instanceof Error) return val.message === "";

  switch (Object.prototype.toString.call(val)) {
    // String or Array
    case "[object String]":
    case "[object Array]":
      return !val.length;

    // Map or Set or File
    case "[object File]":
    case "[object Map]":
    case "[object Set]": {
      return !val.size;
    }
    // Plain Object
    case "[object Object]": {
      return !Object.keys(val).length;
    }
  }

  return false;
}

/**
 * @description: 执行js表达式，兼容性实现eval()
 * @param {type}
 * @return:
 */
export function evil(fn) {
  let Fn = Function; // 一个变量指向Function，防止有些前端编译工具报错
  return new Fn("return " + fn)();
}

/**
 * @description: 获取url中的query
 * @param {variable} 需要获取的key值
 */
export function getQueryVariable(variable) {
  let rawQuery =
    (window.location.search ? window.location.search.substring(1) : "") ||
    window.location.href.split("?")[1];
  if (!rawQuery) {
    return null;
  }
  let reg = new RegExp("(^|&)" + variable + "=([^&]*)(&|$)");
  let r = rawQuery.match(reg); //search,查询？后面的参数，并匹配正则
  if (r != null) {
    return decodeURIComponent(r[2]);
  }
  return null;
}

export function getAbsoluteUrl(url) {
  let a = {};
  a = document.createElement("A");
  a.href = url; // 设置相对路径给Image, 此时会发送出请求
  url = a.href; // 此时相对路径已经变成绝对路径
  return url;
}
/**
 * @description: 获取url参数对象或字符串
 * @param {String} url
 * @param {String} 指定参数
 * @return {type}
 */
export function getParamByUrl(url, par) {
  // let fileUrl = ""; //文件地址
  let listParam = ""; //参数集合
  let listParamObj = {}; //
  let listParamArr = ""; //包含所有参数
  //去掉hash
  url = url.replace("#", "");
  //获取文件地址
  // fileUrl = url.split("?")[0];
  listParam = url.split("?");
  if (listParam.length > 1) {
    listParam.shift();
    let listParam2 = listParam.join();
    listParamArr = listParam2.split("&");
    listParamArr.forEach(function (ele) {
      let temp = ele.split("=");
      listParamObj[temp[0]] = temp[1];
    });
  } else {
    console.log("没有参数");
  }
  if (par == "all") {
    //返回全部参数
    return listParamObj;
  } else {
    //返回指定参数
    for (const key in listParamObj) {
      if (key == par) {
        return JSON.parse(
          "{" + '"' + par + '"' + ":" + '"' + listParamObj[par] + '"' + "}"
        );
      } else {
        console.log("没有传入的参数");
      }
    }
  }
}

// 首字母大小
export function titleCase(str) {
  return str.replace(/( |^)[a-z]/g, (L) => L.toUpperCase());
}

// 下划转驼峰
export function camelCase(str) {
  return str.replace(/-[a-z]/g, (str1) => str1.substr(-1).toUpperCase());
}

function stringify(obj) {
  return JSON.stringify(obj, (key, val) => {
    if (typeof val === "function") {
      return `${val}`;
    }
    return val;
  });
}

function parse(str) {
  JSON.parse(str, (k, v) => {
    if (v.indexOf && v.indexOf("function") > -1) {
      return evil(`(${v})`);
    }
    return v;
  });
}

export function jsonClone(obj) {
  return parse(stringify(obj));
}

// 深拷贝对象
export function deepClone(obj) {
  const _toString = Object.prototype.toString;

  // null, undefined, non-object, function
  if (!obj || typeof obj !== "object") {
    return obj;
  }

  // DOM Node
  if (obj.nodeType && "cloneNode" in obj) {
    return obj.cloneNode(true);
  }

  // Date
  if (_toString.call(obj) === "[object Date]") {
    return new Date(obj.getTime());
  }

  // RegExp
  if (_toString.call(obj) === "[object RegExp]") {
    const flags = [];
    if (obj.global) {
      flags.push("g");
    }
    if (obj.multiline) {
      flags.push("m");
    }
    if (obj.ignoreCase) {
      flags.push("i");
    }

    return new RegExp(obj.source, flags.join(""));
  }

  const result = Array.isArray(obj)
    ? []
    : obj.constructor
    ? new obj.constructor()
    : {};

  for (const key in obj) {
    result[key] = deepClone(obj[key]);
  }

  return result;
}

//获取宽度
export var getWidth = function getWidth(elem) {
  var width =
    elem &&
    typeof elem.getBoundingClientRect === "function" &&
    elem.getBoundingClientRect().width;
  if (width) {
    width = +width.toFixed(6);
  }
  return width || 0;
};

//设置样式
export var setStyle = function setStyle(elem, styleProperty, value) {
  if (elem && typeof elem.style === "object") {
    elem.style[styleProperty] = value;
  }
};

/**
 * @description 生成UUID
 * @param {Integer} len uuid长度
 * @param {Integer} radix uuid基数
 * @returns {String} UUID
 */
export function uuid(len, radix) {
  let chars =
    "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz".split("");
  let uuid = [],
    i;
  radix = radix || chars.length;

  if (len) {
    // Compact form
    for (i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)];
  } else {
    // rfc4122, version 4 form
    let r;
    // rfc4122 requires these characters
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = "-";
    uuid[14] = "4";
    // Fill in random data.  At i==19 set the high bits of clock sequence as
    // per rfc4122, sec. 4.1.5
    for (i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | (Math.random() * 16);
        uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r];
      }
    }
  }
  return uuid.join("");
}

export function loadScript(src, callback) {
  var script = document.createElement("script"),
    head = document.getElementsByTagName("head")[0];
  script.type = "text/javascript";
  script.charset = "UTF-8";
  script.src = src;
  if (script.addEventListener) {
    script.addEventListener(
      "load",
      function () {
        callback();
      },
      false
    );
  } else if (script.attachEvent) {
    script.attachEvent("onreadystatechange", function () {
      var target = window.event.srcElement;
      if (target.readyState == "loaded") {
        callback();
      }
    });
  }
  head.appendChild(script);
}

//青鸾需求部分
/**
 * @description: 数字省略显示，例如：8549会显示为8K+，7812334显示为7M+
 */
export function omitNum(num = 0) {
  if (num > 1000000) {
    let newNum = Math.floor(num / 1000000);
    return `${newNum}M+`;
  }
  if (num > 1000) {
    let newNum = Math.floor(num / 1000);
    return `${newNum}K+`;
  }
  return num;
}

//防抖
export function debounce(func, wait = 300, immediate = true) {
  let timeout;
  return function (...args) {
    let context = this;
    if (timeout) clearTimeout(timeout);
    if (immediate) {
      let callNow = !timeout;
      timeout = setTimeout(function () {
        timeout = null;
      }, wait);
      if (callNow) func.apply(context, args);
    } else {
      timeout = setTimeout(function () {
        func.apply(context, args);
      }, wait);
    }
  };
}

//节流
export function throttle(fn, delay = 300) {
  let flag = true;
  return function (...args) {
    if (!flag) return;
    flag = false;
    setTimeout(() => {
      fn.call(this, ...args);
      flag = true;
    }, delay);
  };
}

//处理时间格式 输入为后端返回的yyyy-mm-dd hh:mm:ss
export function handleTime(val) {
  let duration = moment(val).calendar();
  if (duration.indexOf("今天") > -1 || duration.indexOf("昨天") > -1)
    return duration;
  else {
    if (moment(val).fromNow().indexOf("2 天前") == 0) {
      return "前天";
    } else return moment(val).format("YYYY-MM-DD");
  }
}

//数字格式化 10000 => 1.0万 100000000 => 1.0亿
export function numberFormat(num) {
  const W = 1000;
  const Y = 1000000;
  let str;
  if (!num) {
    str = "0";
  } else if (num < W) {
    str = num;
  } else if (num < Y) {
    str = (num / W).toFixed(1) + "k";
  } else {
    // str = (num / Y).toFixed(2) + '亿'
  }

  return str;
}

//临时权限控制筛选菜单数组（个人中心、创作中心专用）
// export function filterMenuList(arr, code) {
//   const ifAuthorised = nodePermission(code, { types: [1] });
//   let reArr = arr.filter((item) => {
//     return (
//       ifAuthorised ||
//       (!item.path.includes("group") && !item.path.includes("topic"))
//     );
//   });
//   return reArr;
// }

const Dvalue = () => {
  return `${storage.get("ts-D-value")}` == "null"
    ? 0
    : new Number(`${storage.get("ts-D-value")}`);
};

const setTs = () => {
  return new Promise((resolve) => {
    getTs()
      .then((res) => {
        if (res && !isNaN(res)) {
          storage.set("ts-D-value", res - moment().unix());
        } else {
          storage.set("ts-D-value", 0);
        }
        resolve(true);
      })
      .catch(() => {
        storage.set("ts-D-value", 0);
        resolve(true);
      });
  });
};

//获取加密后的url参数字符串
const getUrl = (rest, ttl = TTL) => {
  let Params = "";
  let ts = moment().unix() + Dvalue();
  let obj = rest;
  Params +=
    "ts=" +
    ts +
    "&ttl=" +
    ttl +
    "&uid=" +
    CLIENT +
    (isEmpty(obj) ? "" : "&" + objTransUrlParams(obj));
  let ParamArr = sortUrlParams(Params);
  ParamArr = objKeySort(ParamArr);
  let paramstr = [];
  for (let i in ParamArr) {
    paramstr.push(i + "=" + ParamArr[i]);
  }
  paramstr = paramstr.join("&");
  let signWordArray = HmacSHA1(paramstr, CLIENT_SECRET);
  let sign = Base64.stringify(signWordArray);
  let encodeSign = encodeURIComponent(sign);
  return paramstr + "&sign=" + encodeSign;
};

//获取加密后的参数对象
const getSign = (rest) => {
  let Params = "";
  let ts = moment().unix() + Dvalue();
  let ttl = TTL;
  let obj = rest;
  Params +=
    "ts=" +
    ts +
    "&ttl=" +
    ttl +
    "&uid=" +
    CLIENT +
    (isEmpty(obj) ? "" : "&" + objTransUrlParams(obj));
  let ParamArr = sortUrlParams(Params);
  ParamArr = objKeySort(ParamArr);
  let paramstr = [];
  for (let i in ParamArr) {
    paramstr.push(i + "=" + ParamArr[i]);
  }
  paramstr = paramstr.join("&");
  let signWordArray = HmacSHA1(paramstr, CLIENT_SECRET);
  let sign = Base64.stringify(signWordArray);
  return { sign, ts, ttl, uid: CLIENT, ...ParamArr };
};

const objKeySort = (obj) => {
  let newkey = Object.keys(obj).sort();
  let newObj = {};
  for (let i = 0; i < newkey.length; i++) {
    newObj[newkey[i]] = obj[newkey[i]];
  }
  return newObj;
};

const sortUrlParams = (str) => {
  if (typeof str !== "string") {
    return {};
  }
  let paramObj = {};
  let paramArr = decodeURI(str).split("&");
  // let paramArr = str.split('&');
  for (let i = 0; i < paramArr.length; i++) {
    let tmp = paramArr[i].split("=");
    let key = tmp[0];
    let value = tmp[1] || "";
    //if (typeof value === 'string' && isNaN(Number(value)) === false && value !== "") {
    //  value = Number(value);
    //}
    if (typeof paramObj[key] === "undefined") {
      paramObj[key] = value;
    } else {
      let newValue = Array.isArray(paramObj[key])
        ? paramObj[key]
        : [paramObj[key]];
      newValue.push(value);
      paramObj[key] = newValue;
    }
  }
  return paramObj;
};

const objTransUrlParams = (obj) => {
  const params = [];
  Object.keys(obj).forEach((key) => {
    let value = obj[key];
    if (typeof value === "undefined") {
      value = "";
    }
    params.push([key, value].join("="));
  });
  return params.join("&");
};
const getSrc = (fileToken) => {
  let downloadUrl = API.VUE_APP_BASE_API + "/sys-storage/download_image";
  return downloadUrl + `?f8s=${fileToken}`;
};

const getImgSrc = (fileToken) => {
  return require("@/assets/downloadImage/" + fileToken);
};

const addVideoSignByFksToken = (content, editorType = "markdown") => {
  // console.log(content.indexOf('fawkes-f8s:') )
  if (!content) {
    return content;
  }
  if (content.indexOf("fawkes-f8s:") == -1) {
    return content;
  }
  const srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i;
  const downloadUrl = API.VUE_APP_BASE_API + "/sys-storage/download";

  if (editorType != "markdown") {
    nextTick(() => {
      Prism.highlightAll();
    });
  }

  return content.replace(
    /<source [^>]*src=['"]([^'"]+)[^>]*>/gi,
    function (match) {
      return match.replace(srcReg, function (_match, _ca) {
        if (_ca.indexOf("fawkes-f8s:") == -1) {
          return 'src="' + _ca + '"';
        } else {
          return (
            'src="' +
            downloadUrl +
            "?" +
            getUrl({ f8s: _ca.replace("fawkes-f8s:", "") }) +
            '"'
          );
        }
      });
    }
  );
};

// 转义枚举
export function escapeENUM(code, list) {
  for (let i = 0; i < list.length; ++i) {
    if (list[i].code === String(code)) {
      return list[i]["zh-CN"];
    }
  }
}
// 转义Tree枚举
export function escapeTreeENUM(code, list) {
  for (let i = 0; i < list.length; ++i) {
    if (list[i].code === String(code)) {
      return list[i]["zh-CN"];
    }
    if (list[i].child) {
      let name = escapeTreeENUM(code, list[i].child);
      if (name) return name;
    }
  }
}
export { setTs, getUrl, getSign, getSrc,getImgSrc, addVideoSignByFksToken };
