/*
 * @Author: zhong_m
 * @Date: 2021-12-06 11:33:49
 * @LastEditTime: 2022-01-17 15:08:25
 * @LastEditors: zhong_m
 * @Description: 高级功能默认值，系统配置中修改
 * @FilePath: \central-system\src\config\app\advanced.js
 */
const { SOCKET_TYPE, CAPTCHA_TYPE } = require("../enums");

module.exports = {
  //实时消息socket连接配置
  realTimePush: false, //实时推送
  socketType: SOCKET_TYPE.SMQTT,

  /**待集成开始 */
  maxReconnect: 2, //最大重连次数
  reconnectInterval: 1000, //重连间隔。单位：毫秒
  /**待集成结束 */

  /**
   * @type {boolean} true | false
   * @description 是否开启国际化 语言配置 1.6 为 localLang
   */
  i18n: false,

  /**以下待集成 */

  /**
   * @type {boolean} true | false
   * @description 开发者模式
   */
  developerMode: true,
  /**
   * @type {string}
   * @description 开发者模式密码
   */
  developerPwd: "fawkes@2077",
  /**
   * @type {boolean} true | false
   * @description 是否开启注册按钮
   */
  openRegister: true,
  /**
   * @type {string}
   * @description 行为验证码类型
   */
  captchaType: CAPTCHA_TYPE.BLOCK_PUZZLE,

  /**
   * @type {boolean} true | false
   * @description 用户行为采集
   */
  behaviorAnalysis: true,
};
