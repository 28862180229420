import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/brand-display",
    name: "brand-display",
    component: () =>
      import(
        /* webpackChunkName: "BrandDisplay" */ "../views/BrandDisplay.vue"
      ),
  },
  {
    path: "/product-list",
    name: "productlist",
    component: () =>
      import(/* webpackChunkName: "Product" */ "../views/ProductResearch.vue"),
  },
  {
    path: "/product/:id",
    name: "product",
    component: () =>
      import(/* webpackChunkName: "Product" */ "../views/Product.vue"),
  },
  {
    path: "/productTemp",
    name: "productTemp",
    component: () =>
      import(/* webpackChunkName: "ProductTemp" */ "../views/ProductTemp.vue"),
    children: [
      {
        path: "fawkes",
        component: () =>
          import(
            /* webpackChunkName: "Fawkes" */ "../views/ProductTemp/Fawkes.vue"
          ),
      },
      {
        path: "fawkes/foundation",
        component: () =>
          import(
            /* webpackChunkName: "Fawkes" */ "../views/ProductTemp/Fawkes/Foundation.vue"
          ),
      },
      {
        path: "fawkes/lowcode",
        component: () =>
          import(
            /* webpackChunkName: "Fawkes" */ "../views/ProductTemp/Fawkes/LowCode.vue"
          ),
      },
      {
        path: "fawkes/app",
        component: () =>
          import(
            /* webpackChunkName: "Fawkes" */ "../views/ProductTemp/Fawkes/App.vue"
          ),
      },
      {
        path: "twin/cyber-twin",
        component: () =>
          import(
            /* webpackChunkName: "ProductTemp" */ "../views/ProductTemp/Twin/CyberTwin.vue"
          ),
      },
      {
        path: "twin/bim-simulation",
        component: () =>
          import(
            /* webpackChunkName: "ProductTemp" */ "../views/ProductTemp/Twin/BimSimulation.vue"
          ),
      },
      {
        path: "shiwu",
        component: () =>
          import(
            /* webpackChunkName: "ProductTemp" */ "../views/ProductTemp/ShiWu.vue"
          ),
      },
      {
        path: "yunpeng",
        component: () =>
          import(
            /* webpackChunkName: "ProductTemp" */ "../views/ProductTemp/YunPeng.vue"
          ),
      },
      {
        path: "BIM",
        component: () =>
          import(/* webpackChunkName: "BIM" */ "../views/ProductTemp/BIM.vue"),
      },
      {
        path: "BIM/survey",
        component: () =>
          import(
            /* webpackChunkName: "BIM" */ "../views/ProductTemp/BIM/Survey.vue"
          ),
      },
      {
        path: "BIM/electric",
        component: () =>
          import(
            /* webpackChunkName: "BIM" */ "../views/ProductTemp/BIM/Electric.vue"
          ),
      },
      {
        path: "BIM/civil",
        component: () =>
          import(
            /* webpackChunkName: "BIM" */ "../views/ProductTemp/BIM/Civil.vue"
          ),
      },
      {
        path: "BIM/linearity",
        component: () =>
          import(
            /* webpackChunkName: "BIM" */ "../views/ProductTemp/BIM/Linearity.vue"
          ),
      },
      {
        path: "ai",
        component: () =>
          import(
            /* webpackChunkName: "ProductTemp" */ "../views/ProductTemp/AI.vue"
          ),
      },
    ],
  },
  {
    path: "/activity",
    name: "activity",
    component: () =>
      import(/* webpackChunkName: "Activity" */ "../views/Activity.vue"),
    children: [
      {
        path: "",
        name: "index",
        component: () =>
          import(
            /* webpackChunkName: "Activity" */ "../views/Activity/Index.vue"
          ),
      },
      {
        path: "all",
        component: () =>
          import(
            /* webpackChunkName: "Activity" */ "../views/Activity/All.vue"
          ),
      },
    ],
  },
  {
    path: "/blog/:id",
    name: "blogDetail",
    component: () =>
        import(/* webpackChunkName: "Product" */ "../views/Blog/index.vue"),
  },
  {
    path: "/activity/:id",
    name: "activityDetail",
    component: () =>
        import(/* webpackChunkName: "Product" */ "../views/Event/index.vue"),
  },
  {
    path: "/ecological-cooperation",
    name: "EcologicalCooperation",
    component: () =>
      import(
        /* webpackChunkName: "EcologicalCooperation" */ "../views/EcologicalCooperation.vue"
      ),
    children: [
      {
        path: "joint-ventures",
        component: () =>
          import(
            /* webpackChunkName: "EcologicalCooperation" */ "../views/EcologicalCooperation/JointVentures.vue"
          ),
      },
    ],
  },
  {
    path: "/preview",
    name: "preview",
    component: () =>
      import(
        /* webpackChunkName: "PreView" */ "../views/PreView/KKPreview.vue"
      ),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
});

export default router;
