/*
 * @Author: gao_m3
 * @Date: 2020-12-08 08:43:18
 * @LastEditors: wei_jt@ecidi.com
 * @LastEditTime: 2022-01-28 17:37:45
 * @Descripttion:
 */
// import Cookies from 'js-cookie'
import * as types from "./stateTypes";
import storage from "@/utils/storage";
import defaultSettings from "@/config";

const {
  captchaType,
  realTimePush,
  socketType,
  maxReconnect,
  reconnectInterval,
  openRegister,
  behaviorAnalysis,
  developerMode,
  i18n,
} = defaultSettings;
const state = {
  [types.SIDE_BAR]: {
    // opened: Cookies.get( 'sidebarStatus' ) ? !!+Cookies.get( 'sidebarStatus' ) : true,
    opened: true,
    withoutAnimation: false,
  },
  [types.DEVICE]: "desktop",
  [types.SIDE_BAR_LOGO]: true,
  [types.AUTH_CONFIG]: {
    isGradeAuth: false, //授权类型 ，true为分级
    gradeAuthType: 0, //继承类型,true为单继承
  },
  [types.LATEST_CLIENT_CONFIG]: {},
  [types.INTERFACE_CONFIG]: {},
  [types.LOGIN_STRATEGY]: { captchaType },
  [types.ACCOUNT_CONFIG]: {},
  [types.WATERMARK_CONFIG]: {},
  [types.ADVANCED_CONFIG]: {
    realTimePush,
    socketType,
    maxReconnect,
    reconnectInterval,
    openRegister,
    behaviorAnalysis,
    i18n,
  },
  [types.LANG]: {},
  [types.VISITED_VIEWS]: [],
  [types.CACHED_VIEWS]: [],
  [types.USER]: storage.get("user") ? JSON.parse(storage.get("user")) : {},
  [types.HAS_UNREAD_MESSAGE]: false,
  [types.RECEIVED_MESSAGE]: false,
  [types.ENUM]: {},
  [types.ROUTES]: [],
  [types.PERMISSION]: {},
  [types.BUTTONS]: [],
  [types.PORTALS]: [],
  [types.PORTAL]: storage.get("portal")
    ? JSON.parse(storage.get("portal"))
    : {},
  [types.CLIENT_NAME]: "",
  [types.CURRENT_LANG]: storage.get("lang_config") || "",
  [types.WEBSOCKET_POOL]: [],
  [types.PERMISSION_QUEUE]: new Set(),
  [types.DEVELOPER_FLAG]:
    developerMode && sessionStorage.getItem("developerFlag") === "1",
  //青鸾需求部分
  [types.LOGINDIALOGVISIBLE]: false,
  [types.REMESSAGE]: false,
  [types.UNREADMESSAGE]: false,
  [types.UNREADMSGNUM]: 0,
  [types.TASK]: {},
  [types.ACHV]: {},
};

export default state;
