// import Vue from "vue";
import * as types from "./actionTypes";
import * as mutationTypes from "../Mutation/mutationTypes";
// import * as getterTypes from "../Getter/getterTypes";
import { getEnum } from "@/api/enum";
import {
  getToken,
  getLang,
  getClientConfig,
  socialBind,
  getSocialInfoByToken,
  setClientConfig,
  getButtons,
} from "@/api/app";
import { getMsgByIdAndName } from "@/api/message";
import {
  getPortals,
  getUserInfo,
  getPermissions,
  getCommunityUserInfo,
} from "@/api/user";
import storage from "../../utils/storage";
import { setToken, removeToken } from "../../utils/auth";
import { isJSON } from "@/utils/util";
import {
  matchFormatToTimestamp,
  matchTimestampToFormat,
} from "../../utils/timezone";
// import router from "@/permission";
import { Message } from "@arco-design/web-vue";
const actions = {
  // 登录系统,登录后初始化等相关操作统一处理
  [types.LOGIN]({ dispatch }, portal) {
    dispatch(types.UPDATE_USER, portal).then((res) => {
      if (res.status && res.data) {
        dispatch(types.INIT_WEBSOCKET);
      }
    });
  },

  //用户登录
  [types.USER_LOGIN]({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      getToken(data)
        .then(async (res) => {
          if (res.id) {
            //加载用户信息
            let result = await dispatch(types.LOADING_USER_DATA, res);
            if (result) {
              return resolve(res);
            }
          }
          reject("");
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  //第三方登录
  [types.SOCIAL_LOGIN]({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      getSocialInfoByToken(data)
        .then(async (res) => {
          if (res) {
            if (res.userType === 1) {
              //已绑定，直接登录
              res.access_token = data;
              //加载用户信息
              let result = await dispatch(types.LOADING_USER_DATA, res);
              if (!result) {
                return reject("");
              }
            }
            //返回用户类型，未绑定需跳转绑定
            return resolve(res.userType);
          }
          reject("");
        })
        .catch((e) => {
          reject("");
        });
    });
  },
  //绑定用户
  [types.BIND_USER]({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      socialBind(data)
        .then(async (res) => {
          if (res.id) {
            //加载用户信息
            let result = await dispatch(types.LOADING_USER_DATA, res);
            if (result) {
              return resolve(res);
            }
          }
          reject("");
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  //刷新token
  [types.REFRESH_TOKEN]({ commit }, data) {
    return new Promise((resolve, reject) => {
      getToken(data)
        .then((res) => {
          if (res.access_token) {
            let user = JSON.stringify(res);
            storage.set("access_token", res.access_token);
            storage.set("user", user);
            // 存cookie值
            setToken(res.access_token, user);
            return resolve("success");
          }
          resolve("");
        })
        .catch((e) => {
          resolve("");
        });
    });
  },
  //根据门户获取用户信息
  [types.UPDATE_USER](
    { commit, rootState, dispatch },
    portalId = rootState.portal.id
  ) {
    return new Promise((resolve, reject) => {
      getUserInfo({ userName: `${storage.get("username")}`, portalId })
        .then(async (res) => {
          if (res.status && res.data) {
            //合并数据
            const user = res.data;
            let communityUser = await dispatch(
              types.GET_COMMUNITY_USER_DATA,
              storage.get("username")
            );
            let userData = {};
            Object.assign(userData, user, communityUser);
            //更新缓存与状态仓库用户数据
            storage.set("user", JSON.stringify(userData));
            commit(mutationTypes.SET_USER, userData);
            //水印判断
            commit(mutationTypes.SET_WATERMARK, userData);
          }
          resolve(res);
        })
        .catch((e) => resolve(e));
    });
  },
  //加载应用配置
  [types.LOADING_INIT_DATA]({ dispatch }) {
    return new Promise((resolve, reject) => {
      Promise.all([
        // dispatch(types.GET_CLIENT_CONFIG),
        dispatch(types.GET_ENUMS),
      ])
        .then((res) => {
          resolve(res);
        })
        .catch((e) => resolve(e));
    });
  },
  //加载用户信息
  [types.LOADING_USER_DATA]({ commit, rootGetters, dispatch }, data) {
    return new Promise(async (resolve, reject) => {
      //登录时，保存token
      if (data) {
        storage.set("username", data.userName);
        storage.set("user", JSON.stringify(data));
        data.access_token && storage.set("access_token", data.access_token);
        // 存cookie值
        setToken(data.access_token, data);
      }
      //非登录页刷新,需要判断token
      if (
        storage.get("access_token") &&
        "undefined" != storage.get("access_token")
      ) {
        let portal = "";
        //判断是否多门户
        if (rootGetters.multiPortal) {
          portal = await dispatch(types.GET_PORTALS);
          //无门户权限
          if (!portal) {
            storage.remove("access_token");
            removeToken();
            resolve(false);
            return false;
          }
        }
        // 执行系统登录
        await dispatch(types.LOGIN, portal);
        await dispatch(types.GET_BUTTONS);
      }
      resolve(true);
    }).catch((e) => {
      return false;
    });
  },

  //获取CLIENTINFO，包含通用配置、安全策略、服务器配置
  [types.GET_CLIENT_CONFIG]({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      getClientConfig()
        .then((res) => {
          if (res.status && res.data) {
            commit(mutationTypes.SET_CLIENT_CONFIG, { ...res.data });
            dispatch(types.GET_LANGS);
          }
          resolve(res);
        })
        .catch((err) => resolve(err));
    });
  },
  // 统一修改配置的方法
  [types.PUT_CLIENT_CONFIG]({ commit }, data) {
    // 提交需要object类型并且时间为时间戳
    const addStr = matchFormatToTimestamp(
      JSON.stringify(data.additionalInformation)
    );
    const nData = { ...data, additionalInformation: JSON.parse(addStr) };
    return setClientConfig(nData)
      .then((res) => {
        if (res.status) {
          // 更新本地配置直接用object类型非时间戳
          commit(mutationTypes.SET_CLIENT_CONFIG, data);
        }
        return res;
      })
      .catch(() => {});
  },
  //获取LATEST_CLIENT_CONFIG
  [types.GET_LATEST_CLIENT_CONFIG]({ commit }) {
    return getClientConfig()
      .then((res) => {
        if (res.status && res.data) {
          // 对时间戳进行解析
          let additionalInformation = JSON.parse(
            matchTimestampToFormat(res.data.additionalInformation)
          );
          commit(mutationTypes.SET_LATEST_CLIENT_CONFIG, {
            ...res.data,
            additionalInformation,
          });
        }
        return res;
      })
      .catch(() => {});
  },

  //获取语种
  [types.GET_LANGS]({ commit, rootState, rootGetters }) {
    return new Promise((resolve, reject) => {
      if (!rootGetters.i18n || !rootState.enum.languages) {
        return resolve(0);
      }
      resolve(rootState.enum.languages);
    });
  },

  //获取语言资源
  [types.GET_LANG]({ commit }, lang_config) {
    getLang(lang_config)
      .then((res) => {
        if (res.status && res.data) {
          commit(mutationTypes.SET_ONLINE_LANG, { ...res.data });
        }
      })
      .catch(() => {});
  },
  //获取枚举列表
  [types.GET_ENUMS]({ commit }) {
    return new Promise((resolve, reject) => {
      // getEnum()
      //   .then((res) => {
      //     if (res.status && res.data) {
      //       commit(mutationTypes.SET_ENUM, res.data);
      //     }
      //     resolve(res);
      //   })
      //   .catch((err) => resolve(err));
      let data={
        "moduleName": [
          {
            "code": "blog",
            "zh-CN": "博客",
            "en": ""
          },
          {
            "code": "home",
            "zh-CN": "首页",
            "en": ""
          },
          {
            "code": "login",
            "zh-CN": "登录页",
            "en": ""
          },
          {
            "code": "blogHome",
            "zh-CN": "博客页",
            "en": ""
          },
          {
            "code": "qaHome",
            "zh-CN": "问答页",
            "en": ""
          },
          {
            "code": "question",
            "zh-CN": "问题详情",
            "en": ""
          },
          {
            "code": "personalcenter",
            "zh-CN": "个人中心",
            "en": ""
          },
          {
            "code": "fawkesProdDocContent",
            "zh-CN": "文档详情",
            "en": ""
          },
          {
            "code": "homePage",
            "zh-CN": "课程首页",
            "en": ""
          },
          {
            "code": "100thAnniversary",
            "zh-CN": "100周年活动模块",
            "en": ""
          },
          {
            "code": "userMsgCenter",
            "zh-CN": "消息中心",
            "en": ""
          },
          {
            "code": "courseDetail",
            "zh-CN": "课程详情",
            "en": ""
          },
          {
            "code": "course",
            "zh-CN": "课程",
            "en": ""
          },
          {
            "code": "qa",
            "zh-CN": "问答",
            "en": ""
          },
          {
            "code": "doc",
            "zh-CN": "文档",
            "en": ""
          }
        ],
        "meta": [
          {
            "code": "activeMenu",
            "en": "activeMenu",
            "zh-CN": ""
          },
          {
            "code": "alwaysShow",
            "en": "alwaysShow",
            "zh-CN": ""
          },
          {
            "code": "token",
            "zh-CN": "TOKEN",
            "en": ""
          },
          {
            "code": "id",
            "zh-CN": "ID",
            "en": ""
          },
          {
            "code": "affix",
            "zh-CN": "AFFIX",
            "en": ""
          },
          {
            "code": "isFullScreen",
            "zh-CN": "IS_FULL_SCREEN",
            "en": ""
          },
          {
            "code": "noCache",
            "zh-CN": "NO_CACHE",
            "en": ""
          }
        ],
        "sex": [
          {
            "code": "1",
            "zh-CN": "男",
            "en": ""
          },
          {
            "code": "2",
            "zh-CN": "女",
            "en": ""
          }
        ],
        "boolean": [
          {
            "code": "0",
            "zh-CN": "否",
            "en": ""
          },
          {
            "code": "1",
            "zh-CN": "是",
            "en": ""
          }
        ],
        "currency": [
          {
            "code": "CNY",
            "zh-CN": "人民币",
            "en": ""
          },
          {
            "code": "USD",
            "zh-CN": "美金",
            "en": ""
          },
          {
            "code": "AED",
            "zh-CN": "阿联酋迪拉姆",
            "en": ""
          },
          {
            "code": "OMR",
            "zh-CN": "阿曼里亚尔",
            "en": ""
          },
          {
            "code": "PESO",
            "zh-CN": "菲律宾比索",
            "en": ""
          },
          {
            "code": "KES",
            "zh-CN": "肯尼亚先令",
            "en": ""
          },
          {
            "code": "INR",
            "zh-CN": "印度卢比",
            "en": ""
          },
          {
            "code": "MYR",
            "zh-CN": "马来西亚林吉特",
            "en": ""
          },
          {
            "code": "MXN",
            "zh-CN": "墨西哥元",
            "en": ""
          },
          {
            "code": "NGN",
            "zh-CN": "尼日利亚奈拉",
            "en": ""
          },
          {
            "code": "EUR",
            "zh-CN": "欧元",
            "en": ""
          },
          {
            "code": "CHF",
            "zh-CN": "瑞士法郎",
            "en": ""
          },
          {
            "code": "SAR",
            "zh-CN": "沙特里亚尔",
            "en": ""
          },
          {
            "code": "THB",
            "zh-CN": "泰铢",
            "en": ""
          },
          {
            "code": "TTD",
            "zh-CN": "特立尼达和多巴哥元",
            "en": ""
          },
          {
            "code": "IDR",
            "zh-CN": "印尼卢比",
            "en": ""
          },
          {
            "code": "VND",
            "zh-CN": "越南盾",
            "en": ""
          }
        ],
        "leave_type_test": [],
        "em_form_seal_type": [
          {
            "code": "common",
            "zh-CN": "公章",
            "en": ""
          }
        ],
        "portalType": [
          {
            "code": "1",
            "zh-CN": "一级门户",
            "en": ""
          },
          {
            "code": "2",
            "zh-CN": "二级门户",
            "en": ""
          },
          {
            "code": "3",
            "zh-CN": "三级门户",
            "en": ""
          },
          {
            "code": "4",
            "zh-CN": "四级门户",
            "en": ""
          },
          {
            "code": "5",
            "zh-CN": "五级门户",
            "en": ""
          },
          {
            "code": "6",
            "zh-CN": "六级门户",
            "en": ""
          }
        ],
        "areas": [
          {
            "code": "1",
            "zh-CN": "中国",
            "en": ""
          },
          {
            "code": "2",
            "zh-CN": "美国",
            "en": ""
          },
          {
            "code": "3",
            "zh-CN": "英国",
            "en": ""
          },
          {
            "code": "4",
            "zh-CN": "日本",
            "en": ""
          },
          {
            "code": "5",
            "zh-CN": "泰国",
            "en": ""
          },
          {
            "code": "6",
            "zh-CN": "俄罗斯",
            "en": ""
          },
          {
            "code": "7",
            "zh-CN": "韩国",
            "en": ""
          }
        ],
        "content_status": [
          {
            "code": "TOP",
            "zh-CN": "置顶中",
            "en": ""
          },
          {
            "code": "PUBLISH",
            "zh-CN": "已发布",
            "en": ""
          },
          {
            "code": "REVOKE",
            "zh-CN": "待发布",
            "en": ""
          },
          {
            "code": "AUDIT",
            "zh-CN": "审核中",
            "en": ""
          },
          {
            "code": "FAILURE",
            "zh-CN": "未过审",
            "en": ""
          },
          {
            "code": "DRAFT",
            "zh-CN": "草稿",
            "en": ""
          }
        ],
        "flow_type": [
          {
            "code": "FD",
            "zh-CN": "财务",
            "en": ""
          },
          {
            "code": "OVERSEA",
            "zh-CN": "外事",
            "en": ""
          },
          {
            "code": "INTEGRATED",
            "zh-CN": "综合管理",
            "en": ""
          },
          {
            "code": "HR",
            "zh-CN": "人力资源",
            "en": ""
          },
          {
            "code": "IT",
            "zh-CN": "信息数字技术",
            "en": ""
          },
          {
            "code": "TECH",
            "zh-CN": "科技管理",
            "en": ""
          },
          {
            "code": "MANUFACTURE",
            "zh-CN": "生产管理",
            "en": ""
          },
          {
            "code": "DOCUMENT",
            "zh-CN": "图档管理",
            "en": ""
          },
          {
            "code": "MARKET",
            "zh-CN": "市场经营",
            "en": ""
          }
        ],
        "ql_modes": [
          {
            "code": "1",
            "zh-CN": "处理中",
            "en": ""
          },
          {
            "code": "2",
            "zh-CN": "审核中",
            "en": ""
          },
          {
            "code": "3",
            "zh-CN": "待上架",
            "en": ""
          },
          {
            "code": "4",
            "zh-CN": "上架",
            "en": ""
          },
          {
            "code": "6",
            "zh-CN": "退回",
            "en": ""
          }
        ],
        "language": [
          {
            "code": "zh",
            "zh-CN": "中文",
            "en": ""
          },
          {
            "code": "en",
            "zh-CN": "英文",
            "en": ""
          }
        ],
        "event": [
          {
            "code": "ENTER",
            "zh-CN": "进入",
            "en": ""
          },
          {
            "code": "EXIT",
            "zh-CN": "退出",
            "en": ""
          },
          {
            "code": "FAILED_TO _ENTER",
            "zh-CN": "进入失败",
            "en": ""
          },
          {
            "code": "EXIT_FAILED",
            "zh-CN": "退出失败",
            "en": ""
          }
        ],
        "logLevel": [
          {
            "code": "DANGER",
            "zh-CN": "严重",
            "en": ""
          },
          {
            "code": "WARNING",
            "zh-CN": "警告",
            "en": ""
          },
          {
            "code": "INFO",
            "zh-CN": "信息",
            "en": ""
          }
        ],
        "ql_brand_page": [
          {
            "code": "brand-display",
            "zh-CN": "品牌展示",
            "en": ""
          },
          {
            "code": "product-fawkes",
            "zh-CN": "凤翎",
            "en": ""
          },
          {
            "code": "product-cyber-twin",
            "zh-CN": "孪图",
            "en": ""
          },
          {
            "code": "activity",
            "zh-CN": "活动资讯",
            "en": ""
          },
          {
            "code": "ecological-cooperation",
            "zh-CN": "生态合作",
            "en": ""
          },
          {
            "code": "product-shiwu",
            "zh-CN": "石坞",
            "en": ""
          },
          {
            "code": "product-yunpeng",
            "zh-CN": "云鹏",
            "en": ""
          }
        ],
        "em_form_passportType": [
          {
            "code": "Business",
            "zh-CN": "因公",
            "en": ""
          },
          {
            "code": "Private",
            "zh-CN": "因私",
            "en": ""
          }
        ],
        "colorList": [
          {
            "code": "#1890FF",
            "zh-CN": "拂晓蓝（默认）",
            "en": ""
          },
          {
            "code": "#FF4004",
            "zh-CN": "赤羽红",
            "en": ""
          },
          {
            "code": "#39C5BB",
            "zh-CN": "初音绿",
            "en": ""
          },
          {
            "code": "#005FE1",
            "zh-CN": "青鸾主题色",
            "en": ""
          }
        ],
        "ql_sys_tags": [
          {
            "code": "TAG_SERVER",
            "zh-CN": "服务器",
            "en": ""
          },
          {
            "code": "TAG_FRONT_END",
            "zh-CN": "前端",
            "en": ""
          },
          {
            "code": "TAG_BACK_END",
            "zh-CN": "后端",
            "en": ""
          },
          {
            "code": "TAG_CLOUD_SERVER",
            "zh-CN": "云服务器",
            "en": ""
          },
          {
            "code": "TAG_CSS",
            "zh-CN": "CSS",
            "en": ""
          },
          {
            "code": "TAG_JAVA",
            "zh-CN": "Java",
            "en": ""
          },
          {
            "code": "TAG_JS",
            "zh-CN": "JavaScript",
            "en": ""
          },
          {
            "code": "TAG_SMART_ROBOT",
            "zh-CN": "智能机器人",
            "en": ""
          },
          {
            "code": "TAG_OBJECT_STORAGE",
            "zh-CN": "对象存储",
            "en": ""
          },
          {
            "code": "TAG_SSL",
            "zh-CN": "SSL证书",
            "en": ""
          }
        ],
        "notify_methods": [
          {
            "code": "0",
            "zh-CN": "邮件",
            "en": ""
          },
          {
            "code": "1",
            "zh-CN": "EUC",
            "en": ""
          },
          {
            "code": "2",
            "zh-CN": "短信",
            "en": ""
          }
        ],
        "ql_group_type": [
          {
            "code": "kaleidoscope",
            "zh-CN": "生活万花筒",
            "en": ""
          },
          {
            "code": "leisure",
            "zh-CN": "休闲俱乐部",
            "en": ""
          },
          {
            "code": "stations",
            "zh-CN": "学习加油站",
            "en": ""
          },
          {
            "code": "work",
            "zh-CN": "工作那些事儿",
            "en": ""
          }
        ],
        "politics_type": [
          {
            "code": "MASSES",
            "zh-CN": "群众",
            "en": ""
          },
          {
            "code": "Probationary Party member",
            "zh-CN": "中共预备党员",
            "en": ""
          },
          {
            "code": "Revolution",
            "en": "Party member of the Democratic Revolution",
            "zh-CN": ""
          },
          {
            "code": "MEMBERS_DEMOCRATIC_LEAGUE",
            "en": "Members of the Democratic League",
            "zh-CN": ""
          },
          {
            "code": "MENBERS_CIVIL_ENGINEERING",
            "en": "Members of Civil Engineering",
            "zh-CN": ""
          },
          {
            "code": "DPP",
            "en": "DPP members",
            "zh-CN": ""
          },
          {
            "code": "MEMBERS_RURAL_LABOR_PARTY",
            "en": "A member of the Rural Labor Party",
            "zh-CN": ""
          },
          {
            "code": "ZHIGONG_PARTY",
            "en": "Zhigong Party member",
            "zh-CN": ""
          },
          {
            "code": "JIUSAN_SOCIETY",
            "en": "Member of Jiusan Society",
            "zh-CN": ""
          },
          {
            "code": "TAIWAN_LEAGUE",
            "en": "Members of the Taiwan League",
            "zh-CN": ""
          },
          {
            "code": "NONPARTY",
            "en": "Personages without party affiliation",
            "zh-CN": ""
          },
          {
            "code": "COMMUNIST_YOUTH",
            "zh-CN": "共青团员",
            "en": ""
          },
          {
            "code": "CPC_MEMBER",
            "zh-CN": "中共党员",
            "en": ""
          }
        ],
        "duty": [],
        "ql_feedback_types": [
          {
            "code": "1",
            "zh-CN": "界面错位",
            "en": ""
          },
          {
            "code": "2",
            "zh-CN": "功能优化",
            "en": ""
          },
          {
            "code": "3",
            "zh-CN": "系统BUG",
            "en": ""
          },
          {
            "code": "0",
            "zh-CN": "其他",
            "en": ""
          }
        ],
        "repeat_type": [
          {
            "code": "0",
            "zh-CN": "不提醒",
            "en": ""
          },
          {
            "code": "1",
            "zh-CN": "开始前提醒",
            "en": ""
          }
        ],
        "degree_type": [
          {
            "code": "DOCTOR",
            "zh-CN": "博士",
            "en": ""
          },
          {
            "code": "MASTER",
            "zh-CN": "硕士",
            "en": ""
          },
          {
            "code": "UNDERGRADUATE",
            "zh-CN": "本科",
            "en": ""
          },
          {
            "code": "SPECIALTY",
            "zh-CN": "专科",
            "en": ""
          },
          {
            "code": "UNDERGRAD",
            "zh-CN": "肄业",
            "en": ""
          }
        ],
        "ql_intelligence_type": [
          {
            "code": "industry_trends",
            "zh-CN": "行业动向",
            "en": ""
          },
          {
            "code": "policies_regulations",
            "zh-CN": "政策法规",
            "en": ""
          },
          {
            "code": "standard_specification",
            "zh-CN": "标准规范",
            "en": ""
          },
          {
            "code": "research_report",
            "zh-CN": "研究报告",
            "en": ""
          },
          {
            "code": "water_new_energy",
            "zh-CN": "水电与新能源",
            "en": ""
          },
          {
            "code": "urban_rural_construction",
            "zh-CN": "城乡建设",
            "en": ""
          },
          {
            "code": "ecology _environment",
            "zh-CN": "生态与环境",
            "en": ""
          },
          {
            "code": "artificial_intelligence",
            "zh-CN": "人工智能",
            "en": ""
          },
          {
            "code": "bim",
            "zh-CN": "BIM",
            "en": ""
          },
          {
            "code": "big_data",
            "zh-CN": "大数据",
            "en": ""
          },
          {
            "code": "iot",
            "zh-CN": "物联网",
            "en": ""
          }
        ],
        "purchase_type": [
          {
            "code": "machine",
            "zh-CN": "设备采购",
            "en": ""
          },
          {
            "code": "service",
            "zh-CN": "服务采购",
            "en": ""
          }
        ],
        "scopeType": [
          {
            "code": "1",
            "zh-CN": "全部可见",
            "en": ""
          },
          {
            "code": "2",
            "zh-CN": "不可见",
            "en": ""
          },
          {
            "code": "3",
            "zh-CN": "本人可见",
            "en": ""
          },
          {
            "code": "4",
            "zh-CN": "部门可见",
            "en": ""
          }
        ],
        "extraWay": [
          {
            "code": "GITEE",
            "zh-CN": "gitee",
            "en": ""
          },
          {
            "code": "DINGTALK",
            "en": "Ding Talk",
            "zh-CN": ""
          },
          {
            "code": "ALIPAY",
            "zh-CN": "支付宝",
            "en": ""
          },
          {
            "code": "QQ",
            "zh-CN": "QQ",
            "en": ""
          },
          {
            "code": "WECHAT_OPEN",
            "zh-CN": "微信开放平台",
            "en": ""
          },
          {
            "code": "WECHAT_ENTERPRISE",
            "zh-CN": "企业微信",
            "en": ""
          }
        ],
        "icon_style": [
          {
            "code": "DEFAULT_STYLE",
            "zh-CN": "默认风格",
            "en": ""
          },
          {
            "code": "STYLE_ONE",
            "zh-CN": "风格一",
            "en": ""
          },
          {
            "code": "STYLE_TWO",
            "zh-CN": "风格二",
            "en": ""
          }
        ],
        "ql_meta_config": [
          {
            "code": "invisible",
            "zh-CN": "导航栏隐藏",
            "en": ""
          },
          {
            "code": "untag",
            "zh-CN": "标签栏隐藏",
            "en": ""
          },
          {
            "code": "fullScreen",
            "zh-CN": "全屏显示",
            "en": ""
          },
          {
            "code": "affix",
            "en": "标签栏固定",
            "zh-CN": "标签栏固定"
          },
          {
            "code": "token",
            "zh-CN": "携带token",
            "en": ""
          },
          {
            "code": "merge",
            "zh-CN": "子菜单覆盖",
            "en": ""
          },
          {
            "code": "noCache",
            "zh-CN": "关闭缓存",
            "en": ""
          },
          {
            "code": "customApp",
            "zh-CN": "自定义应用",
            "en": ""
          },
          {
            "code": "defaultOpened",
            "zh-CN": "默认展开",
            "en": ""
          },
          {
            "code": "isClient",
            "zh-CN": "客户端路由",
            "en": ""
          },
          {
            "code": "isHeader",
            "zh-CN": "客户端导航栏",
            "en": ""
          },
          {
            "code": "emptyLayout",
            "zh-CN": "空白布局",
            "en": ""
          },
          {
            "code": "defaultLayout",
            "zh-CN": "默认布局",
            "en": ""
          },
          {
            "code": "isApprovalMode",
            "zh-CN": "审批模式",
            "en": ""
          },
          {
            "code": "noToolButton",
            "zh-CN": "隐藏工具按钮",
            "en": ""
          }
        ],
        "qingluan_service_catagory_type": [
          {
            "code": "1",
            "zh-CN": "服务",
            "en": ""
          },
          {
            "code": "2",
            "zh-CN": "模块",
            "en": ""
          },
          {
            "code": "3",
            "zh-CN": "组合",
            "en": ""
          },
          {
            "code": "4",
            "zh-CN": "解决方案",
            "en": ""
          },
          {
            "code": "5",
            "zh-CN": "sdk",
            "en": ""
          },
          {
            "code": "6",
            "zh-CN": "api",
            "en": ""
          }
        ],
        "video_tags": [
          {
            "code": "FRONT_END",
            "zh-CN": "前端",
            "en": ""
          },
          {
            "code": "BACK_END",
            "zh-CN": "后端",
            "en": ""
          },
          {
            "code": "ARCHITECTURE",
            "zh-CN": "架构",
            "en": ""
          },
          {
            "code": "OPERATION_ AND_ MAINTENANCE",
            "zh-CN": "运维",
            "en": ""
          },
          {
            "code": "PRODUCT",
            "zh-CN": "产品",
            "en": ""
          },
          {
            "code": "OTHER",
            "zh-CN": "其他",
            "en": ""
          }
        ],
        "sys_system_timezone": [
          {
            "code": "GMT",
            "zh-CN": "格林威标准时间：都柏林，爱丁堡，伦敦，里斯本",
            "en": ""
          },
          {
            "code": "GMT+08",
            "zh-CN": "北京，重庆，香港，乌鲁木齐",
            "en": ""
          }
        ],
        "routeTypes": [
          {
            "code": "route",
            "zh-CN": "路由跳转",
            "en": ""
          },
          {
            "code": "iframe",
            "zh-CN": "嵌入页面",
            "en": ""
          },
          {
            "code": "open",
            "zh-CN": "新窗口",
            "en": ""
          }
        ],
        "safetyIncident": [
          {
            "code": "LOG-2147483647-UsualLog-SecurityLog-LoginLog",
            "zh-CN": "登录事件",
            "en": ""
          },
          {
            "code": "LOG-2147483647-UsualLog-SecurityLog-LogoutLog",
            "zh-CN": "登出事件",
            "en": ""
          },
          {
            "code": "LOG-2147483647-UsualLog-SecurityLog-RoleUnauthorizedLog",
            "zh-CN": "角色越权访问事件",
            "en": ""
          },
          {
            "code": "LOG-2147483647-UsualLog-SecurityLog-TokenUnauthorizedLog",
            "zh-CN": "非法token事件",
            "en": ""
          },
          {
            "code": "LOG-2147483647-UsualLog-SecurityLog-SignUnauthorizedLog",
            "zh-CN": "非法签名事件",
            "en": ""
          },
          {
            "code": "LOG-2147483647-UsualLog-SecurityLog-SysConfigChangeLog",
            "zh-CN": "系统配置变更事件",
            "en": ""
          }
        ],
        "reminder": [
          {
            "code": "0",
            "zh-CN": "无",
            "en": ""
          },
          {
            "code": "300000",
            "zh-CN": "5分钟",
            "en": ""
          },
          {
            "code": "600000",
            "zh-CN": "10分钟",
            "en": ""
          },
          {
            "code": "900000",
            "zh-CN": "15分钟",
            "en": ""
          },
          {
            "code": "1800000",
            "zh-CN": "30分钟",
            "en": ""
          },
          {
            "code": "3600000",
            "zh-CN": "1小时",
            "en": ""
          },
          {
            "code": "7200000",
            "zh-CN": "2小时",
            "en": ""
          },
          {
            "code": "10800000",
            "zh-CN": "3小时",
            "en": ""
          },
          {
            "code": "14400000",
            "zh-CN": "4小时",
            "en": ""
          },
          {
            "code": "28800000",
            "zh-CN": "8小时",
            "en": ""
          },
          {
            "code": "43200000",
            "zh-CN": "12小时",
            "en": ""
          },
          {
            "code": "86400000",
            "zh-CN": "1天",
            "en": ""
          },
          {
            "code": "172800000",
            "zh-CN": "2天",
            "en": ""
          },
          {
            "code": "259200000",
            "zh-CN": "3天",
            "en": ""
          },
          {
            "code": "604800000",
            "zh-CN": "1星期",
            "en": ""
          },
          {
            "code": "1209600000",
            "zh-CN": "2星期",
            "en": ""
          }
        ],
        "em_form_category": [
          {
            "code": "FA",
            "zh-CN": "财务资产",
            "en": ""
          },
          {
            "code": "IT",
            "zh-CN": "信息技术",
            "en": ""
          },
          {
            "code": "OB",
            "zh-CN": "海外事业",
            "en": ""
          },
          {
            "code": "EC",
            "zh-CN": "企业综合",
            "en": ""
          },
          {
            "code": "HR",
            "zh-CN": "人力资源",
            "en": ""
          },
          {
            "code": "TC",
            "zh-CN": "科技综合",
            "en": ""
          },
          {
            "code": "MM",
            "zh-CN": "市场经营",
            "en": ""
          },
          {
            "code": "EM",
            "zh-CN": "设备成套",
            "en": ""
          },
          {
            "code": "BD",
            "zh-CN": "图书档案",
            "en": ""
          },
          {
            "code": "PT",
            "zh-CN": "生产技术",
            "en": ""
          },
          {
            "code": "TS",
            "zh-CN": "测验",
            "en": ""
          }
        ],
        "executor_route_strategy": [
          {
            "code": "RANDOM",
            "zh-CN": "随机",
            "en": ""
          },
          {
            "code": "ROUND",
            "zh-CN": "轮询",
            "en": ""
          },
          {
            "code": "LEAST_RECENTLY_USED",
            "zh-CN": "最近最久未使用",
            "en": ""
          },
          {
            "code": "FAILOVER",
            "zh-CN": "故障转移",
            "en": ""
          },
          {
            "code": "LEAST_FREQUENTLY_USED",
            "zh-CN": "最不经常使用",
            "en": ""
          },
          {
            "code": "BUSYOVER",
            "zh-CN": "忙碌转移",
            "en": ""
          },
          {
            "code": "FIRST",
            "zh-CN": "第一个",
            "en": ""
          },
          {
            "code": "LAST",
            "zh-CN": "最后一个",
            "en": ""
          },
          {
            "code": "CONSISTENT_HASH",
            "zh-CN": "一致性HASH",
            "en": ""
          },
          {
            "code": "SHARDING_BROADCAST",
            "zh-CN": "分片广播",
            "en": ""
          }
        ],
        "http_method": [
          {
            "code": "GET",
            "zh-CN": "GET",
            "en": ""
          },
          {
            "code": "POST",
            "zh-CN": "POST",
            "en": ""
          },
          {
            "code": "PUT",
            "zh-CN": "PUT",
            "en": ""
          },
          {
            "code": "DELETE",
            "zh-CN": "DELETE",
            "en": ""
          }
        ],
        "ql_recommend_position": [
          {
            "code": "mobile_start",
            "zh-CN": "APP开屏图",
            "en": ""
          },
          {
            "code": "advertisement",
            "zh-CN": "广告位",
            "en": ""
          },
          {
            "code": "banner",
            "zh-CN": "横幅",
            "en": ""
          },
          {
            "code": "activity",
            "zh-CN": "活动推荐",
            "en": ""
          },
          {
            "code": "course",
            "zh-CN": "课程推荐",
            "en": ""
          },
          {
            "code": "announcement",
            "zh-CN": "公告推荐",
            "en": ""
          },
          {
            "code": "top",
            "zh-CN": "首页轮播",
            "en": ""
          },
          {
            "code": "specialAdvertisement",
            "zh-CN": "专题页广告位",
            "en": ""
          },
          {
            "code": "blogAdvertisement",
            "zh-CN": "博客首页广告位",
            "en": ""
          },
          {
            "code": "brandDisplay",
            "zh-CN": "品牌展示轮播-品牌站",
            "en": ""
          },
          {
            "code": "activityDisplay",
            "zh-CN": "品牌活动轮播-品牌站",
            "en": ""
          }
        ],
        "modules": [
          {
            "code": "ALL",
            "zh-CN": "全部",
            "en": ""
          },
          {
            "code": "test",
            "zh-CN": "测试",
            "en": ""
          },
          {
            "code": "sys_dictionary_category_flow",
            "zh-CN": "流程",
            "en": ""
          },
          {
            "code": "sys_dictionary_category_system",
            "zh-CN": "系统",
            "en": ""
          },
          {
            "code": "sys_dictionary_category_common",
            "zh-CN": "通用",
            "en": ""
          },
          {
            "code": "sys_dictionary_category_form",
            "zh-CN": "表单",
            "en": ""
          },
          {
            "code": "sys_dictionary_category_auth",
            "zh-CN": "权限",
            "en": ""
          }
        ],
        "ql_recommend_module": [
          {
            "code": "announcement",
            "zh-CN": "公告",
            "en": ""
          },
          {
            "code": "groupTopic",
            "zh-CN": "讨论",
            "en": ""
          },
          {
            "code": "group/home",
            "zh-CN": "小组",
            "en": ""
          },
          {
            "code": "activity",
            "zh-CN": "活动",
            "en": ""
          },
          {
            "code": "course",
            "zh-CN": "课程",
            "en": ""
          },
          {
            "code": "blog",
            "zh-CN": "博客",
            "en": ""
          },
          {
            "code": "question",
            "zh-CN": "问答",
            "en": ""
          },
          {
            "code": "special",
            "zh-CN": "专题",
            "en": ""
          },
          {
            "code": "brand",
            "zh-CN": "品牌站",
            "en": ""
          }
        ],
        "borrowType": [
          {
            "code": "original",
            "zh-CN": "原件",
            "en": ""
          },
          {
            "code": "scanned copy",
            "zh-CN": "扫描件",
            "en": ""
          }
        ],
        "ql_course_types": [
          {
            "code": "FRONT_END",
            "zh-CN": "前端",
            "en": ""
          },
          {
            "code": "BACK_END",
            "zh-CN": "后端",
            "en": ""
          },
          {
            "code": "ARCHITECTURE",
            "zh-CN": "架构",
            "en": ""
          },
          {
            "code": "OPERATION_ AND_ MAINTENANCE",
            "zh-CN": "运维",
            "en": ""
          },
          {
            "code": "PRODUCT",
            "zh-CN": "产品",
            "en": ""
          },
          {
            "code": "OTHER",
            "zh-CN": "其他",
            "en": ""
          }
        ],
        "glue_type": [
          {
            "code": "GLUE_PYTHON",
            "zh-CN": "GLUE_PYTHON",
            "en": ""
          },
          {
            "code": "GLUE_POWERSHELL",
            "zh-CN": "GLUE_POWERSHELL",
            "en": ""
          },
          {
            "code": "GLUE_PHP",
            "zh-CN": "GLUE_PHP",
            "en": ""
          },
          {
            "code": "GLUE_GROOVY",
            "zh-CN": "GLUE_GROOVY",
            "en": ""
          },
          {
            "code": "BEAN",
            "zh-CN": "BEAN",
            "en": ""
          },
          {
            "code": "GLUE_NODEJS",
            "zh-CN": "GLUE_NODEJS",
            "en": ""
          },
          {
            "code": "GLUE_SHELL",
            "zh-CN": "GLUE_SHELL",
            "en": ""
          }
        ],
        "LicenseType": [
          {
            "code": "trading",
            "zh-CN": "贸易",
            "en": ""
          },
          {
            "code": "service",
            "zh-CN": "服务",
            "en": ""
          }
        ],
        "idcard_type": [
          {
            "code": "IDCARD",
            "zh-CN": "居民身份证",
            "en": ""
          },
          {
            "code": "VISA",
            "zh-CN": "护照",
            "en": ""
          },
          {
            "code": "HKMACAUPASS",
            "zh-CN": "港澳通行证",
            "en": ""
          }
        ],
        "ql_video_groups": [
          {
            "code": "PROCESS_DESIGN",
            "zh-CN": "流程设计",
            "en": ""
          },
          {
            "code": "GET_STARTED",
            "zh-CN": "起步",
            "en": ""
          },
          {
            "code": "QUICK_START",
            "zh-CN": "快速开始",
            "en": ""
          },
          {
            "code": "DEVELOPMENT_SPECIFICATION",
            "zh-CN": "开发规范",
            "en": ""
          },
          {
            "code": "PROJECT_BASIS",
            "zh-CN": "项目基础",
            "en": ""
          },
          {
            "code": "TEST",
            "zh-CN": "测试",
            "en": ""
          }
        ],
        "qingluan_page": [
          {
            "code": "/account",
            "zh-CN": "个人中心主页",
            "en": ""
          },
          {
            "code": "/notification",
            "zh-CN": "消息中心主页",
            "en": ""
          },
          {
            "code": "/blog",
            "zh-CN": "博客主页",
            "en": ""
          },
          {
            "code": "/question",
            "zh-CN": "问答主页",
            "en": ""
          },
          {
            "code": "/",
            "zh-CN": "首页",
            "en": ""
          },
          {
            "code": "/specialActivity/activitydVideo",
            "zh-CN": "100周年参赛视频详情页",
            "en": ""
          },
          {
            "code": "/market/marketList",
            "zh-CN": "市场主页",
            "en": ""
          },
          {
            "code": "/mall/mallHome",
            "zh-CN": "翎羽商城主页",
            "en": ""
          },
          {
            "code": "/updatelog",
            "zh-CN": "更新日志页",
            "en": ""
          },
          {
            "code": "/doc/write",
            "zh-CN": "文档编辑页",
            "en": ""
          },
          {
            "code": "/100thAnniversary",
            "zh-CN": "100周年庆活动详情页",
            "en": ""
          },
          {
            "code": "/personalcenter/userInfoEdit",
            "zh-CN": "个人信息编辑页",
            "en": ""
          },
          {
            "code": "/doc/topic_detail",
            "zh-CN": "专题详情页",
            "en": ""
          },
          {
            "code": "/doc/teams",
            "zh-CN": "全部团队页",
            "en": ""
          },
          {
            "code": "/doc/team",
            "zh-CN": "团队详情页",
            "en": ""
          },
          {
            "code": "/bloghome/draftList",
            "zh-CN": "博客草稿箱",
            "en": ""
          },
          {
            "code": "/subscribe",
            "zh-CN": "全部标签展示页",
            "en": ""
          },
          {
            "code": "/doc/detail",
            "zh-CN": "文档详情页",
            "en": ""
          },
          {
            "code": "/course/courseDetail",
            "zh-CN": "课程介绍页",
            "en": ""
          },
          {
            "code": "/course/allCourse",
            "zh-CN": "全部课程页",
            "en": ""
          },
          {
            "code": "/bloghome/editArticle",
            "zh-CN": "博客编辑页",
            "en": ""
          },
          {
            "code": "/personalcenter",
            "zh-CN": "个人中心页",
            "en": ""
          },
          {
            "code": "/qa/question",
            "zh-CN": "问题详情页",
            "en": ""
          },
          {
            "code": "/home",
            "zh-CN": "首页",
            "en": ""
          },
          {
            "code": "/bloghome/blogHome",
            "zh-CN": "博客主页",
            "en": ""
          },
          {
            "code": "/userHomepage",
            "zh-CN": "其他用户个人中心页",
            "en": ""
          },
          {
            "code": "/login",
            "zh-CN": "登录页",
            "en": ""
          },
          {
            "code": "/userMsgCenter",
            "zh-CN": "消息通知页",
            "en": ""
          },
          {
            "code": "/specialActivity/uploadVideo",
            "zh-CN": "报名参赛页",
            "en": ""
          },
          {
            "code": "/qa/qaHome",
            "zh-CN": "问答主页",
            "en": ""
          },
          {
            "code": "/course/video",
            "zh-CN": "视频详情页",
            "en": ""
          },
          {
            "code": "/bloghome/blog",
            "zh-CN": "博客详情页",
            "en": ""
          },
          {
            "code": "/search",
            "zh-CN": "全文搜索页",
            "en": ""
          },
          {
            "code": "/course/homePage",
            "zh-CN": "课程主页",
            "en": ""
          }
        ],
        "file_urgency": [
          {
            "code": "0",
            "zh-CN": "平件",
            "en": ""
          },
          {
            "code": "1",
            "zh-CN": "紧急",
            "en": ""
          },
          {
            "code": "2",
            "zh-CN": "特急",
            "en": ""
          }
        ],
        "em_form_flag": [],
        "bug_module": [
          {
            "code": "COMMON_OPERATION",
            "en": "COMMON OPERATION",
            "zh-CN": ""
          },
          {
            "code": "MY_TEAM",
            "en": "MY TEAM",
            "zh-CN": ""
          },
          {
            "code": "SETTING",
            "en": "SETTING",
            "zh-CN": ""
          },
          {
            "code": "MY_WARNING",
            "en": "MY WARNING",
            "zh-CN": ""
          },
          {
            "code": "MY_MISSION",
            "en": "MY MISSION",
            "zh-CN": ""
          },
          {
            "code": "WORKBENCH",
            "en": "WORKBENCH",
            "zh-CN": ""
          },
          {
            "code": "MY_MESSAGE",
            "en": "MY MESSAGE",
            "zh-CN": ""
          },
          {
            "code": "MY_PROJECT",
            "en": "MY PROJECT",
            "zh-CN": ""
          }
        ],
        "executor_block_strategy": [
          {
            "code": "SERIAL_EXECUTION",
            "zh-CN": "单机串行",
            "en": ""
          },
          {
            "code": "DISCARD_LATER",
            "zh-CN": "丢弃后续调度",
            "en": ""
          },
          {
            "code": "COVER_EARLY",
            "zh-CN": "覆盖之前调度",
            "en": ""
          }
        ],
        "file_confidential_level": [
          {
            "code": "1",
            "zh-CN": "保密",
            "en": ""
          },
          {
            "code": "0",
            "zh-CN": "公开",
            "en": ""
          },
          {
            "code": "2",
            "zh-CN": "绝密",
            "en": ""
          }
        ],
        "msg_type": [
          {
            "code": "100",
            "zh-CN": "公告",
            "en": ""
          },
          {
            "code": "200",
            "zh-CN": "业务提醒",
            "en": ""
          },
          {
            "code": "300",
            "zh-CN": "私信",
            "en": ""
          },
          {
            "code": "400",
            "zh-CN": "瞬时消息",
            "en": ""
          }
        ],
        "ql_difficultys": [
          {
            "code": "1",
            "zh-CN": "入门",
            "en": ""
          },
          {
            "code": "2",
            "zh-CN": "初级",
            "en": ""
          },
          {
            "code": "3",
            "zh-CN": "中级",
            "en": ""
          },
          {
            "code": "4",
            "zh-CN": "高级",
            "en": ""
          }
        ],
        "scope_type": [
          {
            "code": "3",
            "zh-CN": "部门及子部门可见",
            "en": ""
          },
          {
            "code": "4",
            "zh-CN": "自定义",
            "en": ""
          },
          {
            "code": "5",
            "zh-CN": "全部可见",
            "en": ""
          },
          {
            "code": "2",
            "zh-CN": "部门可见",
            "en": ""
          },
          {
            "code": "1",
            "zh-CN": "本人可见",
            "en": ""
          },
          {
            "code": "0",
            "zh-CN": "不可见",
            "en": ""
          }
        ],
        "ql_report_types": [
          {
            "code": "1",
            "zh-CN": "内容质量太差",
            "en": ""
          },
          {
            "code": "2",
            "zh-CN": "低俗色情",
            "en": ""
          },
          {
            "code": "3",
            "zh-CN": "涉及违法",
            "en": ""
          },
          {
            "code": "4",
            "zh-CN": "恶意营销",
            "en": ""
          },
          {
            "code": "5",
            "zh-CN": "内容抄袭",
            "en": ""
          },
          {
            "code": "6",
            "zh-CN": "侵犯名誉/隐私/著作/肖像权等",
            "en": ""
          },
          {
            "code": "7",
            "zh-CN": "其他原因",
            "en": ""
          }
        ],
        "ql_activity_types": [
          {
            "code": "TYPE_MEETING",
            "zh-CN": "公务会议",
            "en": ""
          },
          {
            "code": "TYPE_THEMED_SHARING",
            "zh-CN": "主题分享",
            "en": ""
          },
          {
            "code": "TYPE_TRAINING",
            "zh-CN": "课程培训",
            "en": ""
          },
          {
            "code": "TYPE_CULTURAL_CREATIVITY",
            "zh-CN": "文创活动",
            "en": ""
          },
          {
            "code": "TYPE_TEAM_BUILDING",
            "zh-CN": "团建活动",
            "en": ""
          },
          {
            "code": "TYPE_EXHIBITIONS",
            "zh-CN": "展览展会",
            "en": ""
          },
          {
            "code": "TYPE_SUMMIT_FORUM",
            "zh-CN": "峰会论坛",
            "en": ""
          },
          {
            "code": "TYPE_THEMATIC_SESSIONS",
            "zh-CN": "专题会议",
            "en": ""
          },
          {
            "code": "TYPE_ROADSHOW_SALON",
            "zh-CN": "路演沙龙",
            "en": ""
          }
        ],
        "ql_blog_new_types": [
          {
            "code": "NT_BUSINESS_TREND",
            "zh-CN": "业务动向",
            "en": ""
          },
          {
            "code": "NT_PRODUCT_APPLICATION",
            "zh-CN": "产品应用",
            "en": ""
          },
          {
            "code": "NT_TECH_DEVELOP",
            "zh-CN": "技术研发",
            "en": ""
          },
          {
            "code": "NT_NEWS",
            "zh-CN": "通知新闻",
            "en": ""
          },
          {
            "code": "NT_RES_SHARE",
            "zh-CN": "资源分享",
            "en": ""
          },
          {
            "code": "NT_LIFE_EXPERIENCE",
            "zh-CN": "生活心得",
            "en": ""
          },
          {
            "code": "NT_INFO",
            "zh-CN": "行业资讯",
            "en": ""
          },
          {
            "code": "NT_OTHERS",
            "zh-CN": "其他",
            "en": ""
          }
        ],
        "ql_product_tags": [
          {
            "code": "business",
            "zh-CN": "业务板块",
            "en": "",
            "child": [
              {
                "code": "hydropower_and_energy",
                "zh-CN": "水电与新能源",
                "en": ""
              },
              {
                "code": "urban_and_rural_construction",
                "zh-CN": "城乡建设",
                "en": ""
              },
              {
                "code": "ecology_and_environment",
                "zh-CN": "生态与环境",
                "en": ""
              }
            ]
          },
          {
            "code": "product",
            "zh-CN": "产品条线",
            "en": "",
            "child": [
              {
                "code": "bim",
                "zh-CN": "[数域经纬]-BIM三维数字化",
                "en": ""
              },
              {
                "code": "RD",
                "zh-CN": "[数域砥柱]-基础研发",
                "en": ""
              },
              {
                "code": "AI",
                "zh-CN": "[数域闻道]-AI人工智能",
                "en": ""
              },
              {
                "code": "hydropower",
                "zh-CN": "[数域百川]-智慧水利水电",
                "en": ""
              },
              {
                "code": "energy",
                "zh-CN": "[数域力势]-智慧能源",
                "en": ""
              },
              {
                "code": "city",
                "zh-CN": "[数域安业]-智慧城市",
                "en": ""
              },
              {
                "code": "survey",
                "zh-CN": "[数域天问]-智慧勘测",
                "en": ""
              },
              {
                "code": "traffic",
                "zh-CN": "[数域通达]-智慧交通",
                "en": ""
              },
              {
                "code": "water",
                "zh-CN": "[数域青石]-智慧水务",
                "en": ""
              },
              {
                "code": "ecology",
                "zh-CN": "[数域山水]-智慧生态",
                "en": ""
              },
              {
                "code": "chief",
                "zh-CN": "[数域风行]-智慧政务",
                "en": ""
              }
            ]
          }
        ],
        "qing_luan_blog_type": [
          {
            "code": "基础研发",
            "zh-CN": "基础研发",
            "en": ""
          },
          {
            "code": "水电与新能源",
            "zh-CN": "水电与新能源",
            "en": ""
          },
          {
            "code": "城乡建设",
            "zh-CN": "城乡建设",
            "en": ""
          },
          {
            "code": "生态与环境",
            "zh-CN": "生态与环境",
            "en": ""
          },
          {
            "code": "其他",
            "zh-CN": "其他",
            "en": ""
          }
        ],
        "marriage_states": [
          {
            "code": "UNMARRIED",
            "zh-CN": "未婚",
            "en": ""
          },
          {
            "code": "MARRIED",
            "zh-CN": "已婚",
            "en": ""
          }
        ],
        "ql_blog_types": [
          {
            "code": "TYPE_URBAN_AND_RURAL",
            "zh-CN": "城乡建设",
            "en": ""
          },
          {
            "code": "TYPE_WATER",
            "zh-CN": "水务生态",
            "en": ""
          },
          {
            "code": "TYPE_ENERGY",
            "zh-CN": "能源电力",
            "en": ""
          },
          {
            "code": "TYPE_FRONT_END",
            "zh-CN": "前端",
            "en": ""
          },
          {
            "code": "TYPE_BACK_END",
            "zh-CN": "后端",
            "en": ""
          },
          {
            "code": "TYPE_BIM",
            "zh-CN": "BIM",
            "en": ""
          },
          {
            "code": "fawkes",
            "zh-CN": "凤翎",
            "en": ""
          },
          {
            "code": "TYPE_PRODUCT",
            "zh-CN": "产品",
            "en": ""
          },
          {
            "code": "TYPE_CLOUD_COMPUTING",
            "zh-CN": "云计算",
            "en": ""
          },
          {
            "code": "SZZG",
            "zh-CN": "数字中国",
            "en": ""
          },
          {
            "code": "TYPE_BIG_DATA",
            "zh-CN": "大数据",
            "en": ""
          },
          {
            "code": "TYPE_DIGITIZATION",
            "zh-CN": "数字化",
            "en": ""
          },
          {
            "code": "TYPE_CIM",
            "zh-CN": "智慧城市",
            "en": ""
          },
          {
            "code": "TYPE_ARTIFICIAL_INTELLIGENCE",
            "zh-CN": "人工智能",
            "en": ""
          },
          {
            "code": "TYPE_DLP_OPER",
            "zh-CN": "开发运维",
            "en": ""
          },
          {
            "code": "TYPE_INTERNET_THINGS",
            "zh-CN": "物联网",
            "en": ""
          },
          {
            "code": "TYPE_GIS",
            "zh-CN": "GIS",
            "en": ""
          },
          {
            "code": "TYPE_CYBERTWIN",
            "zh-CN": "CyberTwin图形平台",
            "en": ""
          },
          {
            "code": "INTELLIGENCE",
            "zh-CN": "行业资讯",
            "en": ""
          },
          {
            "code": "TYPE_OTHER",
            "zh-CN": "其它",
            "en": ""
          }
        ],
        "msg_send_type": [
          {
            "code": "0",
            "zh-CN": "站内信",
            "en": ""
          },
          {
            "code": "1",
            "zh-CN": "短信",
            "en": ""
          },
          {
            "code": "2",
            "zh-CN": "邮件",
            "en": ""
          }
        ],
        "goods_size": [
          {
            "code": "S",
            "zh-CN": "S",
            "en": ""
          },
          {
            "code": "M",
            "zh-CN": "M",
            "en": ""
          },
          {
            "code": "L",
            "zh-CN": "L",
            "en": ""
          },
          {
            "code": "XL",
            "zh-CN": "XL",
            "en": ""
          },
          {
            "code": "XXL",
            "zh-CN": "XXL",
            "en": ""
          },
          {
            "code": "XXXL",
            "zh-CN": "XXXL",
            "en": ""
          },
          {
            "code": "黑轴",
            "zh-CN": "黑轴",
            "en": ""
          },
          {
            "code": "青轴",
            "zh-CN": "青轴",
            "en": ""
          },
          {
            "code": "茶轴",
            "zh-CN": "茶轴",
            "en": ""
          },
          {
            "code": "红轴",
            "zh-CN": "红轴",
            "en": ""
          }
        ],
        "goods_color": [
          {
            "code": "0",
            "zh-CN": "定制款黑色",
            "en": ""
          },
          {
            "code": "1",
            "zh-CN": "定制款蓝色",
            "en": ""
          },
          {
            "code": "2",
            "zh-CN": "定制款粉色",
            "en": ""
          },
          {
            "code": "3",
            "zh-CN": "木槿紫",
            "en": ""
          },
          {
            "code": "4",
            "zh-CN": "月白蓝",
            "en": ""
          },
          {
            "code": "5",
            "zh-CN": "元气绿",
            "en": ""
          },
          {
            "code": "6",
            "zh-CN": "远峰蓝",
            "en": ""
          },
          {
            "code": "7",
            "zh-CN": "樱花粉",
            "en": ""
          },
          {
            "code": "8",
            "zh-CN": "草莓熊",
            "en": ""
          },
          {
            "code": "9",
            "zh-CN": "米妮",
            "en": ""
          },
          {
            "code": "10",
            "zh-CN": "米奇",
            "en": ""
          },
          {
            "code": "11",
            "zh-CN": "珊瑚粉",
            "en": ""
          },
          {
            "code": "12",
            "zh-CN": "云杉绿",
            "en": ""
          },
          {
            "code": "13",
            "zh-CN": "霜灰蓝",
            "en": ""
          },
          {
            "code": "14",
            "zh-CN": "奶茶色-男款",
            "en": ""
          },
          {
            "code": "15",
            "zh-CN": "黑色-男款",
            "en": ""
          },
          {
            "code": "16",
            "zh-CN": "奶茶色-女款",
            "en": ""
          },
          {
            "code": "17",
            "zh-CN": "薄藤紫-女款",
            "en": ""
          },
          {
            "code": "18",
            "zh-CN": "品月蓝",
            "en": ""
          },
          {
            "code": "19",
            "zh-CN": "星暮紫",
            "en": ""
          },
          {
            "code": "20",
            "zh-CN": "烟云灰",
            "en": ""
          },
          {
            "code": "21",
            "zh-CN": "玫瑰粉",
            "en": ""
          },
          {
            "code": "22",
            "zh-CN": "米白色",
            "en": ""
          },
          {
            "code": "23",
            "zh-CN": "石墨黑",
            "en": ""
          },
          {
            "code": "24",
            "zh-CN": "原木色-男款",
            "en": ""
          },
          {
            "code": "25",
            "zh-CN": "原木色-女款",
            "en": ""
          },
          {
            "code": "26",
            "zh-CN": "黑色-女款",
            "en": ""
          },
          {
            "code": "27",
            "zh-CN": "绣球蓝",
            "en": ""
          },
          {
            "code": "28",
            "zh-CN": "茉莉白",
            "en": ""
          },
          {
            "code": "29",
            "zh-CN": "浅樱粉",
            "en": ""
          },
          {
            "code": "30",
            "zh-CN": "鸢尾紫",
            "en": ""
          },
          {
            "code": "31",
            "zh-CN": "薄荷绿",
            "en": ""
          },
          {
            "code": "32",
            "zh-CN": "彩光-白色款",
            "en": ""
          },
          {
            "code": "33",
            "zh-CN": "彩光-黑色款",
            "en": ""
          },
          {
            "code": "34",
            "zh-CN": "彩光-粉色款",
            "en": ""
          },
          {
            "code": "35",
            "zh-CN": "黑色",
            "en": "black"
          },
          {
            "code": "36",
            "zh-CN": "奶白色",
            "en": ""
          },
          {
            "code": "37",
            "zh-CN": "白色",
            "en": ""
          },
          {
            "code": "38",
            "zh-CN": "神秘灰",
            "en": ""
          },
          {
            "code": "39",
            "zh-CN": "新潮蓝",
            "en": ""
          },
          {
            "code": "40",
            "zh-CN": "纯真白",
            "en": ""
          },
          {
            "code": "41",
            "zh-CN": "元气粉",
            "en": ""
          },
          {
            "code": "42",
            "zh-CN": "魔幻黑",
            "en": ""
          },
          {
            "code": "43",
            "zh-CN": "苍岭绿",
            "en": ""
          },
          {
            "code": "44",
            "zh-CN": "深海蓝",
            "en": ""
          },
          {
            "code": "45",
            "zh-CN": "男士渔夫帽-初木棕",
            "en": ""
          },
          {
            "code": "46",
            "zh-CN": "男士渔夫帽-漆夜黑",
            "en": ""
          },
          {
            "code": "47",
            "zh-CN": "男士渔夫帽-深蓝灰",
            "en": ""
          },
          {
            "code": "48",
            "zh-CN": "女士空顶帽-渐变蓝",
            "en": ""
          },
          {
            "code": "49",
            "zh-CN": "女士空顶帽-渐变棕",
            "en": ""
          },
          {
            "code": "50",
            "zh-CN": "女士空顶帽-漫暮黑",
            "en": ""
          },
          {
            "code": "51",
            "zh-CN": "女士空顶帽-奶兮紫",
            "en": ""
          },
          {
            "code": "52",
            "zh-CN": "女士空顶帽-浅茶绿",
            "en": ""
          },
          {
            "code": "53",
            "zh-CN": "女士空顶帽-葡柚紫",
            "en": ""
          },
          {
            "code": "54",
            "zh-CN": "女士空顶帽-生巧棕",
            "en": ""
          },
          {
            "code": "55",
            "zh-CN": "女士空顶帽-霜奶白",
            "en": ""
          },
          {
            "code": "56",
            "zh-CN": "女士空顶帽-浅灰蕉花",
            "en": ""
          },
          {
            "code": "57",
            "zh-CN": "女士空顶帽-浅棕蕉花",
            "en": ""
          },
          {
            "code": "58",
            "zh-CN": "女士空顶帽-卡其花草",
            "en": ""
          },
          {
            "code": "59",
            "zh-CN": "女士空顶帽-深蓝花草",
            "en": ""
          },
          {
            "code": "60",
            "zh-CN": "女士空顶帽-浅紫花草",
            "en": ""
          },
          {
            "code": "61",
            "zh-CN": "女士空顶帽-深棕花草",
            "en": ""
          },
          {
            "code": "62",
            "zh-CN": "银河灰",
            "en": ""
          },
          {
            "code": "63",
            "zh-CN": "漫暮黑",
            "en": ""
          },
          {
            "code": "64",
            "zh-CN": "清烟蓝",
            "en": ""
          },
          {
            "code": "65",
            "zh-CN": "【修身款】乌云灰",
            "en": ""
          },
          {
            "code": "66",
            "zh-CN": "【修身款】漫暮黑",
            "en": ""
          },
          {
            "code": "67",
            "zh-CN": "【修身款】板栗咖",
            "en": ""
          },
          {
            "code": "68",
            "zh-CN": "【修身款】浅绯粉",
            "en": ""
          },
          {
            "code": "69",
            "zh-CN": "【修身款】黎雾蓝",
            "en": ""
          },
          {
            "code": "70",
            "zh-CN": "【修身款】轻鹅黄",
            "en": ""
          },
          {
            "code": "71",
            "zh-CN": "金色",
            "en": ""
          },
          {
            "code": "72",
            "zh-CN": "银色",
            "en": ""
          },
          {
            "code": "73",
            "zh-CN": "黑色",
            "en": ""
          },
          {
            "code": "74",
            "zh-CN": "红色",
            "en": ""
          },
          {
            "code": "75",
            "zh-CN": "蓝色",
            "en": ""
          },
          {
            "code": "76",
            "zh-CN": "灰色",
            "en": ""
          },
          {
            "code": "77",
            "zh-CN": "粉色",
            "en": ""
          },
          {
            "code": "78",
            "zh-CN": "绿色",
            "en": ""
          },
          {
            "code": "79",
            "zh-CN": "运动小C",
            "en": ""
          },
          {
            "code": "80",
            "zh-CN": "看书小C",
            "en": ""
          },
          {
            "code": "81",
            "zh-CN": "乐游小C",
            "en": ""
          }
        ],
        "ql_product_team": [
          {
            "code": "institute_of_mathematical_sciences",
            "zh-CN": "数研院",
            "en": ""
          },
          {
            "code": "urban_planning_institute",
            "zh-CN": "城规院",
            "en": ""
          },
          {
            "code": "institute_of_energy_regulation",
            "zh-CN": "能规院",
            "en": ""
          },
          {
            "code": "hydropower_institute",
            "zh-CN": "水电院",
            "en": ""
          },
          {
            "code": "institute_of_mechanical_and_electrical_engineering",
            "zh-CN": "机电院",
            "en": ""
          },
          {
            "code": "new_energy_institute",
            "zh-CN": "新能源院",
            "en": ""
          },
          {
            "code": "traffic_academy",
            "zh-CN": "交通院",
            "en": ""
          },
          {
            "code": "urban_construction_institute",
            "zh-CN": "城建院",
            "en": ""
          },
          {
            "code": "ecological_institute",
            "zh-CN": "生态院",
            "en": ""
          },
          {
            "code": "east_china_construction_management",
            "zh-CN": "华东建管",
            "en": ""
          },
          {
            "code": "east_china_consulting",
            "zh-CN": "华东咨询",
            "en": ""
          },
          {
            "code": "east_china_geotechnical",
            "zh-CN": "华东岩土",
            "en": ""
          },
          {
            "code": "east_china_safety_testing",
            "zh-CN": "华东测安",
            "en": ""
          },
          {
            "code": "southeast_headquarter",
            "zh-CN": "东南总部",
            "en": ""
          },
          {
            "code": "south_china_headquarter",
            "zh-CN": "华南总部",
            "en": ""
          },
          {
            "code": "western_headquarter",
            "zh-CN": "西部总部",
            "en": ""
          },
          {
            "code": "east_china_headquarter",
            "zh-CN": "华东总部",
            "en": ""
          },
          {
            "code": "central_china_headquarter",
            "zh-CN": "华中总部",
            "en": ""
          },
          {
            "code": "north_china_headquarter",
            "zh-CN": "华北总部",
            "en": ""
          },
          {
            "code": "northeast_headquarter",
            "zh-CN": "东北总部",
            "en": ""
          },
          {
            "code": "donghui_technology",
            "zh-CN": "东慧科技",
            "en": ""
          },
          {
            "code": "digital_communication",
            "zh-CN": "数宇交科",
            "en": ""
          },
          {
            "code": "diji_technology",
            "zh-CN": "迪吉科技",
            "en": ""
          },
          {
            "code": "qianhai_digital",
            "zh-CN": "前海数科",
            "en": ""
          },
          {
            "code": "xiongchuang_digital",
            "zh-CN": "雄创数字",
            "en": ""
          },
          {
            "code": "water_technology",
            "zh-CN": "杭水科技",
            "en": ""
          }
        ],
        "ql_activity_channels": [
          {
            "code": "CHANNEL_BRAND_CAMPAIGNS",
            "zh-CN": "品牌市场活动",
            "en": ""
          },
          {
            "code": "CHANNEL_INTERNAL_CORPORATE",
            "zh-CN": "企业内部活动",
            "en": ""
          }
        ]
      };
      commit(mutationTypes.SET_ENUM, data);
    });
  },
  //获取枚举
  [types.GET_ENUM]({ commit }, code) {
    return new Promise((resolve, reject) => {
      getEnum(code)
        .then((res) => {
          if (res.status && res.data) {
            commit(mutationTypes.SET_ENUM, res.data);
          }
        })
        .catch(() => {});
    });
  },

  [types.GET_BUTTONS]({ state, commit }) {
    //获取菜单下按钮权限
    return new Promise((resolve, reject) => {
      getButtons({ type: state.portal.type })
        .then((res) => {
          if (res.status) {
            commit(mutationTypes.SET_BUTTONS, res.data);
          }
          resolve(true);
        })
        .catch(() => {
          resolve(true);
        });
    });
  },

  [types.GET_PERMISSIONS]({ state, commit }, menuId) {
    //获取按钮权限
    return new Promise((resolve, reject) => {
      getPermissions(menuId)
        .then((res) => {
          if (res.status) {
            commit(mutationTypes.SET_PERMISSION, {
              key: menuId,
              value: res.data,
            });
          }
          resolve(true);
        })
        .catch(() => {
          resolve(true);
        });
    });
  },

  //获取门户
  [types.GET_PORTALS]({ state, commit, dispatch }) {
    return new Promise((resolve, reject) => {
      getPortals()
        .then((res) => {
          let portal = "";
          if (res.data instanceof Array && res.data.length > 0) {
            let portals = res.data.sort((f, b) => {
              return f.type - b.type;
            });
            commit(mutationTypes.SET_PORTALS, portals);

            if (isJSON(`${storage.get("portal")}`)) {
              portal = portals.find((p) => {
                return JSON.parse(`${storage.get("portal")}`).id === p.id;
              });
            }

            !portal && (portal = portals[0]);

            dispatch(types.CHANGE_PORTAL, portal);
          } else {
            Message({
              type: "warning",
              message: state.language.Global.NO_PORTAL_PERMISSION,
            });
            // router.replace("/login");
            this.$router.push({ path: "/login" });
          }
          resolve(portal.id || "");
        })
        .catch((err) => resolve(""));
    });
  },
  //门户切换
  [types.CHANGE_PORTAL]({ commit }, portal) {
    commit(mutationTypes.CLEAN_VIEWS);
    commit(mutationTypes.CLEAN_ROUTE);
    commit(mutationTypes.CLEAN_PERMISSION);
    commit(mutationTypes.CLEAN_PERMISSION_QUEUE);
    commit(mutationTypes.UPDATE_PORTAL, portal);
  },
  [types.CLEAN_DATA]({ commit }) {
    commit(mutationTypes.CLEAN_VIEWS);
    commit(mutationTypes.CLEAN_ROUTE);
    commit(mutationTypes.CLEAN_PERMISSION);
    commit(mutationTypes.SET_PORTALS, []);
    commit(mutationTypes.CLEAN_PERMISSION_QUEUE);
    commit(mutationTypes.CLEAN_STORAGE);
  },
  //通用配置部分
  [types.TOGGLE_SIDEBAR]({ commit }, opened) {
    commit(mutationTypes.TOGGLE_SIDEBAR, opened);
  },
  [types.CLOSE_SIDEBAR]({ commit }, { withoutAnimation }) {
    commit(mutationTypes.CLOSE_SIDEBAR, withoutAnimation);
  },
  [types.TOGGLE_DEVICE]({ commit }, device) {
    commit(mutationTypes.TOGGLE_DEVICE, device);
  },
  [types.CHANGE_INTERFACE]({ commit }, data) {
    commit(mutationTypes.CHANGE_INTERFACE, data);
  },
  [types.CHANGE_WATERMARK]({ commit }, data) {
    commit(mutationTypes.CHANGE_WATERMARK, data);
  },
  //标签栏部分
  [types.ADD_VIEW]({ dispatch }, view) {
    dispatch(types.ADD_VISITED_VIEW, view);
    dispatch(types.ADD_CACHED_VIEW, view);
  },
  [types.ADD_VISITED_VIEW]({ commit }, view) {
    commit(mutationTypes.ADD_VISITED_VIEW, view);
  },
  [types.ADD_CACHED_VIEW]({ commit }, view) {
    commit(mutationTypes.ADD_CACHED_VIEW, view);
  },

  [types.DEL_VIEW]({ dispatch, state }, view) {
    return new Promise((resolve) => {
      dispatch(types.DEL_VISITED_VIEW, view);
      dispatch(types.DEL_CACHED_VIEW, view);
      resolve({
        visitedViews: [...state.visitedViews],
        cachedViews: [...state.cachedViews],
      });
    });
  },
  [types.DEL_VISITED_VIEW]({ commit, state }, view) {
    return new Promise((resolve) => {
      commit(mutationTypes.DEL_VISITED_VIEW, view);
      resolve([...state.visitedViews]);
    });
  },
  [types.DEL_CACHED_VIEW]({ commit, state }, view) {
    return new Promise((resolve) => {
      commit(mutationTypes.DEL_CACHED_VIEW, view);
      resolve([...state.cachedViews]);
    });
  },

  [types.DEL_OTHERS_VIEWS]({ dispatch, state }, view) {
    return new Promise((resolve) => {
      dispatch(types.DEL_OTHERS_VISITED_VIEWS, view);
      dispatch(types.DEL_OTHERS_CACHED_VIEWS, view);
      resolve({
        visitedViews: [...state.visitedViews],
        cachedViews: [...state.cachedViews],
      });
    });
  },
  [types.DEL_OTHERS_VISITED_VIEWS]({ commit, state }, view) {
    return new Promise((resolve) => {
      commit(mutationTypes.DEL_OTHERS_VISITED_VIEWS, view);
      resolve([...state.visitedViews]);
    });
  },
  [types.DEL_OTHERS_CACHED_VIEWS]({ commit, state }, view) {
    return new Promise((resolve) => {
      commit(mutationTypes.DEL_OTHERS_CACHED_VIEWS, view);
      resolve([...state.cachedViews]);
    });
  },

  [types.DEL_ALL_VIEWS]({ dispatch, state }, view) {
    return new Promise((resolve) => {
      dispatch(types.DEL_ALL_VISITED_VIEWS, view);
      dispatch(types.DEL_ALL_CACHED_VIEWS, view);
      resolve({
        visitedViews: [...state.visitedViews],
        cachedViews: [...state.cachedViews],
      });
    });
  },
  [types.DEL_ALL_VISITED_VIEWS]({ commit, state }) {
    return new Promise((resolve) => {
      commit(mutationTypes.DEL_ALL_VISITED_VIEWS);
      resolve([...state.visitedViews]);
    });
  },
  [types.DEL_ALL_CACHED_VIEWS]({ commit, state }) {
    return new Promise((resolve) => {
      commit(mutationTypes.DEL_ALL_CACHED_VIEWS);
      resolve([...state.cachedViews]);
    });
  },
  [types.UPDATE_VISITED_VIEW]({ commit }, view) {
    commit(mutationTypes.UPDATE_VISITED_VIEW, view);
  },
  // 初始化websocket
  // [types.INIT_WEBSOCKET]({ commit, getters }) {
  //   if (getters[getterTypes.REAL_TIME_PUSH]) {
  //     Vue.prototype.$FksWebsocket({
  //       name: "fks-default-websocket",
  //       connectType: getters[getterTypes.SOCKET_TYPE], //'stomp'
  //       url: Vue.prototype.VUE_APP_SOCKET_API,
  //       destination: "/topic/message",
  //       maxReconnect: getters[getterTypes.MAX_RECONNECT],
  //       onNewMessage: () => {
  //         commit(mutationTypes.UPDATE_RECEIVED_MESSAGE, true);
  //       },
  //     });
  //   }
  // },
  /**
   * @description: 检查是否有未读消息
   */
  [types.CHECK_UNREAD_MESSAGE]({ commit, state }) {
    return new Promise((resolve, reject) => {
      let data = {
        currentPage: 1,
        pageSize: 1,
        clientId: state.user.clientId,
        userName: state.user.userName,
        status: -1,
      };
      getMsgByIdAndName(data)
        .then((res) => {
          if (res.status) {
            if (res.data.list.length > 0) {
              commit(mutationTypes.UPDATE_HAS_UNREAD_MESSAGE, true);
            } else {
              commit(mutationTypes.UPDATE_HAS_UNREAD_MESSAGE, false);
            }
            resolve(res);
          }
          reject("");
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  //青鸾需求部分
  [types.GET_COMMUNITY_USER_DATA]({ commit }, userName) {
    //获取社区相关用户信息
    return new Promise((resolve, reject) => {
      getCommunityUserInfo(userName)
        .then((res) => {
          if (res.status) {
            resolve(res.data);
          }
        })
        .catch(() => {
          resolve({});
        });
    });
  },
};

export default actions;
