<template>
  <footer class="footer">
    Copyright© 2023 by www.ecidi.com All Rights Reserved.
  </footer>
</template>

<style lang="scss" scoped>
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;
  background: #36383d;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
}
</style>
